import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Box, Heading } from 'react-bulma-components/dist'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faCreditCard, faCashRegister, faCheck, faPersonBooth } from '@fortawesome/free-solid-svg-icons'
import { CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js'
import { waitForElement } from '@testing-library/react'
import { findAllInRenderedTree } from 'react-dom/test-utils'
import CountrySelect from './CountrySelect'
import { getCurrentCustomer } from '../utilities/Login'
import { currencySymbol, currencyCode } from '../utilities/clientInfo'
import PayPal from './PayPal'





const PaypalPaymentPage = (props) => {


    let [amount, setAmount] = useState(props.finalPrice)
    const [paySuccess, setPaySuccess] = useState(null);
    const [isProcessing, setProcessing] = useState(false);
    const [error, setError] = useState(null)

    const [paypalCheckout, setPayPalCheckout] = useState(false)


    let currency = currencyCode


    let thisCust = getCurrentCustomer()




    const payWithPayPalPressed = () => {
        setPaySuccess(false)
        setError(null)
        setProcessing(true)
        setPayPalCheckout(true)
    }

    const paymentDone = (paymentId, status, amountInHundred, currency, gateway) => {
        setPaySuccess(true)
        setError(null)
        setProcessing(false)
        setPayPalCheckout(false)
        setTimeout( () => {
            props.paymentDone(paymentId, status, amountInHundred, currency, gateway)
        }, 800 )
    }

    const errorOccured = (err) => {
        setProcessing(false)
        setError(err)
        setPaySuccess(false)
        setPayPalCheckout(false)
    }


    const form = () => {

        return (

            <div>

                {
                    paypalCheckout ?
                        <PayPal order={props.order} finalPrice={props.finalPrice} paymentDone={paymentDone} errorOccured={errorOccured} />

                        :

                        <div>
                            <p className='help is-danger marginBottom10 marginTop20 '> {error} </p>
                            {paySuccess ? <p className=" marginTop10 has-text-success has-text-weight-medium"> <FontAwesomeIcon className='' icon={faCheck} /> &nbsp; Success </p> : null}
                            <br />
                            {paySuccess !== true ? <Button renderAs='button' disabled={isProcessing} loading={isProcessing} className='is-fullwidth marginTop20 is-black has-text-weight-medium avenir' onClick={payWithPayPalPressed}  > Pay&nbsp; {currencySymbol}{amount} via PayPal </Button> : null}
                            <br />
                            <p className=" avenir marginTop10 type60 has-text-grey has-text-weight-medium"> <FontAwesomeIcon className='' icon={faLock} /> &nbsp; PCI Compliant. Secured Payments. </p>

                            <br />
                        </div>

                }



            </div>



        )
    }



    return (
        form()
    )

}

export default PaypalPaymentPage