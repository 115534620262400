
//____CHANGE PROCESS______

//1. CHANGE LOGO 
//d EVERYWHERE THERE IS assets/logoTailorwise.svg imported. Change to clientLogo or clientLogoNav or clientLogoMenu. Upload these first.

//2. CHANGE BACKEND APPID, JSKEY & RESTKEY 
//d Change APPID, JSKEY & RESTKEY in .env.development & .env.production

//3. CHANGE STRIPE ACCOUNT ID 
//d Change REACT_APP_STRIPE_PUBLISHABLE_KEY in .env.development & .env.production

//3.2 CHANGE STRIPE SECRET KEY in Cloud Code Folder 
//d Change secret key associated with StripeKey in CloudCode folder.

//4. IF PAYPAL . CHANGE IMPORT LINE in /public/index.html 
//d Change   <script src="https://www.paypal.com/sdk/js?client-id=<CLIENT-KEY>&components=buttons&currency=<CURRENCY-3LETTER-CODE DEFAULT USD>"></script>

//5. CHANGE CONFIG HERE & in WEBSHOP
//d Change isOpenWithoutSignIn in Webshop and hasShop Here.

//6. VALIDATE DOMAIN FOR EMAILS
//d Validate client domain on Sendgrid to make sure emails work as expected.

//7. Upload & copy Assets on AWS
//d Make folder for client, copy measurement images & videos from existing folders into it. And upload clients logo. Input urls underneath.

//8. Change All the info undersneath


export const hasShop = true

export const defaultStoreObjid = "FC237G0HJG"  // Main Store
export const defaultUserObjid = "hywLGNUCi6"  // ROY

export const standardDeliveryTime = "Standard Delivery Time of 2-3 weeks"
export const freeShippingThreshold = 300
export const standardPaidShippingPrice = 35

export const awsBaseUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/dl1/"
export const clientName = "Daniel & Lade"

export const clientPhone = "+44-02033183030"
export const clientEmail = "info@danielandlade.com"
export const clientEmailFromForOrder = "info@danielandlade.com"

// export const clientEmail = "appsquare.in@gmail.com"
// export const clientEmailFromForOrder = "appsquare.in@gmail.com"



export const clientWebsite = "https://www.danielandlade.com"
export const clientTermsAndConditionsUrl = "https://www.danielandlade.com"

export const clientLogoUrl = "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/dl1/logo.png"
export const currencySymbol = "£"
export const currencyCode = "GBP"
export const clientLogoNav = ""
export const clientLogoHero = ""
export const clientBGHome = ""
export const clientBGHero = ""


