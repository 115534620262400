/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from './Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from '../utilities/clientInfo'





// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForPant extends Component {
   
    basePrice = 400

    STEP_KEYS = {
        productType: '',
        pleat: 'PLEAT',
        sidePockets: 'SIDE POCKETS',
        backPockets: 'BACK POCKETS',
        beltLoop: 'BELT LOOP',
        adjuster: 'ADJUSTER',
        pantBottom: 'PANT BOTTOM',
        // lining: 'Lining',
        monogram: 'MONOGRAM',
        
        tone: 'TONE',
        fabric: 'FABRIC'
    }

    TONE_KEYS = {
        allseason: 'allseason',
        winter: 'winter',
        spring: 'spring',
        stripe: 'stripe'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }


    // selected_Pleat : pleat
    // selected_SidePockets : sidePockets
    // selected_BackPockets : backPockets
    // selected_BeltLoop : beltLoop
    // selected_Adjuster : adjuster
    // selected_PantBottom : pantBottom
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric

    state = {
        uploadImage: null,
        
        selected_Pleat: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pleat : null,
        selected_SidePockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sidePockets : null,
        selected_BackPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.backPockets : null,
        selected_BeltLoop: this.props.editingProduct ? this.props.editingProduct.selectedSelections.beltLoop : null,
        selected_Adjuster: this.props.editingProduct ? this.props.editingProduct.selectedSelections.adjuster : null,
        selected_PantBottom: this.props.editingProduct ? this.props.editingProduct.selectedSelections.pantBottom : null,
        // selected_Lining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lining : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,

        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)

            ns.selected_Pleat = existingStyle.selectedSelections.pleat
            ns.selected_SidePockets = existingStyle.selectedSelections.sidePockets
            ns.selected_BackPockets = existingStyle.selectedSelections.backPockets
            ns.selected_BeltLoop = existingStyle.selectedSelections.beltLoop
            ns.selected_Adjuster = existingStyle.selectedSelections.adjuster
            ns.selected_PantBottom = existingStyle.selectedSelections.pantBottom
            // ns.selected_Lining = existingStyle.selectedSelections.lining
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric

        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }




        // selected_Pleat : pleat
    // selected_SidePockets : sidePockets
    // selected_BackPockets : backPockets
    // selected_BeltLoop : beltLoop
    // selected_Adjuster : adjuster
    // selected_PantBottom : pantBottom
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric


    // PLEAT, ["PANT"], ["PLAIN FRONT", "SINGLE PLEAT"]
// FRONT
   pleat_Opts = [
    { optType:"PLEAT", styleSelectionId: "", key: 'PLAIN FRONT', name: 'PLAIN FRONT', imageSrc: webImageNamed('pant/pleat/PLAINFRONT.jpg') },
    { optType:"PLEAT", styleSelectionId: "", key: 'SINGLE PLEAT', name: 'SINGLE PLEAT', imageSrc: webImageNamed('pant/pleat/SINGLEPLEAT.jpg') },
]
pleat_Step = () => {
    return {
        name: 'How do you like the front pleat?',
        key: this.STEP_KEYS.pleat,
        opts: this.pleat_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// SIDE POCKETS, ["PANT"], ["STANDARD", "STRAIGHT"]
// LAPEL
sidePockets_Opts = [
    {optType:"SIDE POCKETS", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('pant/sidepockets/STANDARD.jpg') },
    {optType:"SIDE POCKETS", styleSelectionId: "", key: 'STRAIGHT', name: 'STRAIGHT', imageSrc: webImageNamed('pant/sidepockets/STRAIGHT.jpg') },
]
sidePockets_Step = () => {
    return {
        name: 'How would you like the Side Pockets?',
        key: this.STEP_KEYS.sidePockets,
        opts: this.sidePockets_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// BACK POCKETS, ["PANT"], ["STANDARD RIGHT", "STANDARD LEFT"]

// LAPEL WIDTH
backPockets_Opts = [
    {optType:"BACK POCKETS", styleSelectionId: "", key: 'STANDARD RIGHT', name: 'STANDARD RIGHT', imageSrc: webImageNamed('pant/backpockets/RIGHT.jpg') },
    {optType:"BACK POCKETS", styleSelectionId: "", key: 'STANDARD LEFT', name: 'STANDARD LEFT', imageSrc: webImageNamed('pant/backpockets/LEFT.jpg') },
    {optType:"BACK POCKETS", styleSelectionId: "", key: 'NONE', name: 'NONE', imageSrc: webImageNamed('pant/backpockets/NONE.jpg') },
]
backPockets_Step = () => {
    return {
        name: 'Back Pockets',
        key: this.STEP_KEYS.backPockets,
        opts: this.backPockets_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}

// BELT LOOP, ["PANT"], ["NONE", "STANDARD"]

// LOWER POCKETS
beltLoop_Opts = [
    {optType:"BELT LOOP", styleSelectionId: "", key: 'NONE', name: 'NONE', imageSrc: webImageNamed('pant/beltloops/NONE.jpg') },
    {optType:"BELT LOOP", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('pant/beltloops/STANDARD.jpg') },
]
beltLoop_Step = () => {
    return {
        name: 'Belt Loop',
        key: this.STEP_KEYS.beltLoop,
        opts: this.beltLoop_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: true,
        paddingLess: false,
        boldText: false
    }
}


// ADJUSTER, ["PANT"], ["BUCKLE", "BUTTON"]

// VENT
adjuster_Opts = [
    {optType:"ADJUSTER", styleSelectionId: "", key: 'BUCKLE', name: 'BUCKLE', imageSrc: webImageNamed('pant/beltloops/BUCKLE.jpg') },
    {optType:"ADJUSTER", styleSelectionId: "", key: 'BUTTON', name: 'BUTTON', imageSrc: webImageNamed('pant/beltloops/BUTTON.jpg') },
]
adjuster_Step = () => {
    return {
        name: 'Choose Adjuster',
        key: this.STEP_KEYS.adjuster,
        opts: this.adjuster_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: true,
        paddingLess: false,
        boldText: false
    }
}


// PANT BOTTOM, ["PANT"], ["NORMAL", "CUFF (3.8CM)"]

// SLEEVE BUTTONS
pantBottom_Opts = [
    {optType:"PANT BOTTOM", styleSelectionId: "", key: 'NORMAL', name: 'NORMAL', imageSrc: webImageNamed('pant/pantbottom/NORMAL.jpg') },
    {optType:"PANT BOTTOM", styleSelectionId: "", key: 'CUFF (3.8CM)', name: 'CUFF (3.8CM)', imageSrc: webImageNamed('pant/pantbottom/CUFF38cm.jpg') },
]
pantBottom_Step = () => {
    return {
        name: 'How about Pant Bottom',
        key: this.STEP_KEYS.pantBottom,
        opts: this.pantBottom_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// LINING, ["PANT"], ["FULL", "HALF", "QUARTER"]
// LINING
lining_Opts = [
    {optType:"LINING", styleSelectionId: "", key: 'FULL', name: 'FULL', imageSrc: webImageNamed('TWO') },
    {optType:"LINING", styleSelectionId: "", key: 'HALF', name: 'HALF', imageSrc: webImageNamed('THREE') },
    {optType:"LINING", styleSelectionId: "", key: 'QUARTER', name: 'QUARTER', imageSrc: webImageNamed('FOUR') },
]
lining_Step = () => {
    return {
        name: 'Do you like Full Lining',
        key: this.STEP_KEYS.lining,
        opts: this.lining_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// MONOGRAM, ["PANT"], ["MONOGRAM INSIDE WASITBAND"]
// MONOGRAM
monogram_Opts = [
    {optType:"MONOGRAM", styleSelectionId: "", key: 'MONOGRAM INSIDE WAISTBAND', name: 'MONOGRAM INSIDE WAISTBAND', imageSrc: webImageNamed('pant/monogram/MONOGRAMINSIDEWAISTBAND.jpg'), customInput:"" },
]
monogram_Step = () => {
    return {
        name: 'If and where you would like to have monogram',
        key: this.STEP_KEYS.monogram,
        opts: this.monogram_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: true,
        paddingLess: false,
        boldText: false,
        needsCustomInput: true
    }
}


// TONE, ["PANT"], []
// TONE
toneOpts = [
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.allseason, name: 'ALL SEASONS', imageSrc: webImageNamed('pant/FABRICS/allseason.jpeg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.winter, name: 'AUTUMN & WINTER', imageSrc: webImageNamed('pant/FABRICS/winter.jpg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.spring, name: 'SPRING & SUMMER', imageSrc: webImageNamed('pant/FABRICS/spring.jpg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.stripe, name: 'STRIPES', imageSrc: webImageNamed('pant/FABRICS/stripe.jpg') },
    // { key: 'other', name: 'Other', imageSrc: null },
]
toneStep = () => {
    // Change images here with respect to button choices
    return {
        name: 'What Category are we looking for?',
        key: this.STEP_KEYS.tone,
        opts: this.toneOpts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '3by4',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// FABRIC, ["PANT"], []
fabric_ALLSEASONSOpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBM618A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBM618A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBM706A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBM706A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBP674A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBP674A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBP748A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBP748A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBQ810A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBQ810A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBS133A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBS133A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBS135A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBS135A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBS141A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBS141A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBS146A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBS146A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBS147A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBS147A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU069A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBU069A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU071A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DBU071A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DMB707A', imageSrc: webImageNamed('pant/FABRICS/AllSeason/DMB707A.jpg') , price:null , company:''},
]
fabric_AUTUMNWINTEROpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBQ662A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBQ662A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBR128A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBR128A.jpg') , price:null  , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBT605A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT605A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBT606A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT606A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBT607A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT607A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBT612A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT612A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBT613A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT613A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBT618A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT618A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBT621A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBT621A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBU011A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBU011A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU012A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBU012A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU013A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBU013A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DBU014A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBU014A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '14', name: 'DBU015A', imageSrc: webImageNamed('pant/FABRICS/AutumnWinter/DBU015A.jpg ') , price:null , company:''},
]
fabric_SPRINGSUMMEROpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBN890A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBN890A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBP737A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBP737A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBP739A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBP739A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBS179A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBS179A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBS233A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBS233A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBT1503', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBT1503.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBT586A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBT586A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBT587A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBT587A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBU059A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBU059A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBU086A', imageSrc: webImageNamed('pant/FABRICS/SpringSummer/DBU086A.jpg') , price:null , company:''},
]
fabric_STRIPESOpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBL622A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBL622A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBL647A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBL647A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBL650A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBL650A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBL748A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBL748A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBP645A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBP645A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBP690A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBP690A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBP691A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBP691A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBP753A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBP753A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBS131A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBS131A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBS132A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBS132A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU080A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBU080A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU081A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBU081A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DBU083A', imageSrc: webImageNamed('pant/FABRICS/Stripes/DBU083A.jpg') , price:null , company:''},
]

    fabricImageForSelectedKey = (tone, key) => {
        let opts = null
        switch (tone) {
            case this.TONE_KEYS.allseason: opts = this.fabric_AUTUMNWINTEROpts; break;
            case this.TONE_KEYS.winter: opts = this.fabric_AUTUMNWINTEROpts; break;
            case this.TONE_KEYS.spring: opts = this.fabric_SPRINGSUMMEROpts; break;
            default: opts = this.fabric_STRIPESOpts; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return webImageNamed(thisObj[0].imageSrc)
    }


    optsForTone = (tone) => {
        switch (tone) {
            case this.TONE_KEYS.allseason: return this.fabric_AUTUMNWINTEROpts; 
            case this.TONE_KEYS.winter: return this.fabric_AUTUMNWINTEROpts; 
            case this.TONE_KEYS.spring: return this.fabric_SPRINGSUMMEROpts; 
            default: return this.fabric_STRIPESOpts; 
        }
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.allseason

        let opts = this.optsForTone(thisTone)


        console.log('OPTS NOW =', opts.k)
        console.log( opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }








    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }



     

    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_Pleat
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_Pleat.imageSrc){
                mainImage = this.state.selected_Pleat.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc){
                fabricImage = this.state.selected_Fabric.imageSrc
            }

            let styleName = this.state.productType + "-" + makeid(3)
            
            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }


            let allSelelected = [this.state.selected_Pleat, this.state.selected_SidePockets, this.state.selected_BackPockets, this.state.selected_BeltLoop, 
                this.state.selected_Adjuster, this.state.selected_PantBottom, this.state.selected_Monogram, this.state.selected_Tone, this.state.selected_Fabric]


                let finalPrice = this.basePrice
                let addedPriceObjs = [] 
                let addOnPrice = 0
    
                allSelelected.forEach( asel => {
                    if (asel != null) {
                        if (asel.price) {
                            finalPrice += asel.price
                            addOnPrice += asel.price
                            addedPriceObjs.push( { type: asel.optType, name: asel.name, price: asel.price })
                        }
                    }
    
                })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_Pleat = ")
            console.log(this.state.selected_Pleat)

            let style = {
                pleat: this.state.selected_Pleat,
                sidePockets: this.state.selected_SidePockets,
                backPockets: this.state.selected_BackPockets,
                beltLoop: this.state.selected_BeltLoop,
                adjuster: this.state.selected_Adjuster,
                pantBottom: this.state.selected_PantBottom,
                // lining: this.state.selected_Lining,
                monogram: this.state.selected_Monogram,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric

            }

            console.log("STYLE = ")
            console.log(style)

            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val 
                if (ms[1].customInput){
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)){
                    description += ""
                }else{
                    description += ","
                }


            })

            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol, this.basePrice ,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            // prodObj.key = 'id' + (new Date()).getTime();

            // // let prodObj = {
            // //     key: uniqId,
            // //     styleName:  styleName,
            // //     frontImage: mainImage,
            // //     fabricImage: fabricImage,
            // //     description: description,
            // //     type: this.state.productType,
            // //     basePrice: this.basePrice,
            // //     price: finalPrice,
            // //     addedPriceObjs: addedPriceObjs,
            // //     selectedSelections: style
            // // }

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]


            

            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map( (obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric'){
                    fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone'){
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return { 
                    "key" : obj.optType, 
                    "value" : obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "") , 
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null, 
                    "isConciergeSelection" : true
                 }
            })

            concToTwSelections = concToTwSelections.filter( (obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections
            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

                            // selected_Pleat : pleat
                // selected_SidePockets : sidePockets
                // selected_BackPockets : backPockets
                // selected_BeltLoop : beltLoop
                // selected_Adjuster : adjuster
                // selected_PantBottom : pantBottom
                // selected_Lining : lining
                // selected_Monogram : monogram
                // selected_Tone : tone
                // selected_Fabric : fabric

                // ns[stepKey] = itemKey

                // console.log("ITEM KEY =" + itemKey)
                // let firstOPT = this.pleat_Opts.filter( opt => { return (opt.key === itemKey)})


                // console.log("FIRST OPT")
                // console.log(firstOPT)


            switch (stepKey) {
                case this.STEP_KEYS.pleat: ns.selected_Pleat =  this.pleat_Opts.filter( opt => { return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.sidePockets: ns.selected_SidePockets = this.sidePockets_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.backPockets: ns.selected_BackPockets = this.backPockets_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.beltLoop: ns.selected_BeltLoop = this.beltLoop_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.adjuster: ns.selected_Adjuster = this.adjuster_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.pantBottom: ns.selected_PantBottom = this.pantBottom_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.lining: ns.selected_Lining = this.lining_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter( opt => {return opt.key === itemKey})[0] ; if (customInput && customInput !== ""){ ns.selected_Monogram["customInput"] = customInput } ; break;
                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter( opt => {return opt.key === itemKey})[0]; break;
                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    jacketWizard = () => {


        // selected_Pleat : pleat
        // selected_SidePockets : sidePockets
        // selected_BackPockets : backPockets
        // selected_BeltLoop : beltLoop
        // selected_Adjuster : adjuster
        // selected_PantBottom : pantBottom
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric

    
    // let productStep = this.productStep()
    let pleat_Step = this.pleat_Step()
    let sidePockets_Step = this.sidePockets_Step()
    let backPockets_Step = this.backPockets_Step()
    let beltLoop_Step = this.beltLoop_Step()
    let adjuster_Step = this.adjuster_Step()
    let pantBottom_Step = this.pantBottom_Step()
    // let lining_Step = this.lining_Step()
    let monogram_Step = this.monogram_Step()
    let toneStep = this.toneStep()
    let fabricStep = this.fabricStep()


    let title = "Let's Create a Style"
    let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    let points = [
        'To make it easy we pick the cuts and tones before finding the fabric.',
        'We will measure you right after we have created all the needed styles.'
    ]

    return (<div >
        {/* <div ref={this.mainStepperDiv}></div> */}
        {(this.state.isComplete || this.state.isCancelled) ?
            <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            :
            <StepWizard className=' fadeIn'>
                {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
               {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12whfu3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  

               <Step name={pleat_Step.name}  skipable={pleat_Step.skipable ?? false} stepKey={pleat_Step.key }     exitingSelectedKey={this.state.selected_Pleat ? this.state.selected_Pleat.key  : null }     opts={pleat_Step.opts} isFirst={true} isPhotoBox={pleat_Step.isPhotoBox} photoRatio={pleat_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={pleat_Step.boldText ?? false}  boldText={pleat_Step.boldText ?? false} ></Step>
               <Step name={sidePockets_Step.name}         skipable={sidePockets_Step.skipable ?? false} stepKey={sidePockets_Step.key }                   exitingSelectedKey={this.state.selected_SidePockets ? this.state.selected_SidePockets.key         : null }            opts={sidePockets_Step.opts} isFirst={false} isPhotoBox={sidePockets_Step.isPhotoBox} photoRatio={sidePockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={sidePockets_Step.boldText ?? false}  boldText={sidePockets_Step.boldText ?? false} ></Step>
               <Step name={backPockets_Step.name}    skipable={backPockets_Step.skipable ?? false} stepKey={backPockets_Step.key }         exitingSelectedKey={this.state.selected_BackPockets ? this.state.selected_BackPockets.key    : null }       opts={backPockets_Step.opts} isFirst={false} isPhotoBox={backPockets_Step.isPhotoBox} photoRatio={backPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={backPockets_Step.boldText ?? false}  boldText={backPockets_Step.boldText ?? false} ></Step>               
               <Step name={beltLoop_Step.name}  skipable={beltLoop_Step.skipable ?? false} stepKey={beltLoop_Step.key }     exitingSelectedKey={this.state.selected_BeltLoop ? this.state.selected_BeltLoop.key  : null }     opts={beltLoop_Step.opts} isFirst={false} isPhotoBox={beltLoop_Step.isPhotoBox} photoRatio={beltLoop_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={beltLoop_Step.boldText ?? false}  boldText={beltLoop_Step.boldText ?? false} ></Step>
               <Step name={adjuster_Step.name}          skipable={adjuster_Step.skipable ?? false} stepKey={adjuster_Step.key }                     exitingSelectedKey={this.state.selected_Adjuster ? this.state.selected_Adjuster.key          : null }             opts={adjuster_Step.opts} isFirst={false} isPhotoBox={adjuster_Step.isPhotoBox} photoRatio={adjuster_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={adjuster_Step.boldText ?? false}  boldText={adjuster_Step.boldText ?? false} ></Step>
               <Step name={pantBottom_Step.name} skipable={pantBottom_Step.skipable ?? false} stepKey={pantBottom_Step.key }   exitingSelectedKey={this.state.selected_PantBottom ? this.state.selected_PantBottom.key : null }    opts={pantBottom_Step.opts} isFirst={false} isPhotoBox={pantBottom_Step.isPhotoBox} photoRatio={pantBottom_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={pantBottom_Step.boldText ?? false}  boldText={pantBottom_Step.boldText ?? false} ></Step>
               {/* <Step name={lining_Step.name}        skipable={lining_Step.skipable ?? false} stepKey={lining_Step.key }                 exitingSelectedKey={this.state.selected_Lining ? this.state.selected_Lining.key        : null }           opts={lining_Step.opts} isFirst={false} isPhotoBox={lining_Step.isPhotoBox} photoRatio={lining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lining_Step.boldText ?? false}  boldText={lining_Step.boldText ?? false} ></Step> */}
               <Step name={monogram_Step.name}   needsCustomInput={true}   skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key }             exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key      : null }         opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={monogram_Step.boldText ?? false}  boldText={monogram_Step.boldText ?? false} ></Step>
               <Step name={toneStep.name}           skipable={toneStep.skipable ?? false} stepKey={toneStep.key }                       exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key          : null }             opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={toneStep.boldText ?? false}  boldText={toneStep.boldText ?? false} ></Step>
               <Step name={fabricStep.name}         skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key }                   exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key        : null }           opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={true} paddingLess={fabricStep.boldText ?? false}  boldText={fabricStep.boldText ?? false} ></Step>

            </StepWizard>
        }
        {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    </div>
    )
}



    render() {
        return (
            this.jacketWizard()
        )
    }

}


export default WizardForPant
