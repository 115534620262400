/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from './Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from '../utilities/clientInfo'




// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', webImageNamed('`````'))



class WizardForJacket extends Component {
   
    basePrice = 400

    STEP_KEYS = {
        productType: '',
        frontButtons: 'FRONT BUTTONS',
        lapel: 'LAPEL',
        lapelWidth: 'LAPEL WIDTH',
        lowerPockets: 'LOWER POCKETS',
        vent: 'VENT',
        sleeveButtons: 'SLEEVE BUTTONS',
        lining: 'LINING',
        monogram: 'MONOGRAM',
        
        tone: 'TONE',
        fabric: 'FABRIC'
    }

    TONE_KEYS = {
        winter: 'winter',
        allSeasons: 'allSeasons',
        spring: 'spring',
        stripes: 'stripes'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }


    // selected_FrontButtons : frontButtons
    // selected_Lapel : lapel
    // selected_LapelWidth : lapelWidth
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric

    state = {
        uploadImage: null,
        
        selected_FrontButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.frontButtons : null,
        selected_Lapel: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapel : null,
        selected_LapelWidth: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lapelWidth : null,
        selected_LowerPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lowerPockets : null,
        selected_Vent: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vent : null,
        selected_SleeveButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sleeveButtons : null,
        selected_Lining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lining : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,

        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)

            ns.selected_FrontButtons = existingStyle.selectedSelections.frontButtons
            ns.selected_Lapel = existingStyle.selectedSelections.lapel
            ns.selected_LapelWidth = existingStyle.selectedSelections.lapelWidth
            ns.selected_LowerPockets = existingStyle.selectedSelections.lowerPockets
            ns.selected_Vent = existingStyle.selectedSelections.vent
            ns.selected_SleeveButtons = existingStyle.selectedSelections.sleeveButtons
            ns.selected_Lining = existingStyle.selectedSelections.lining
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric

        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }




        // selected_FrontButtons : frontButtons
    // selected_Lapel : lapel
    // selected_LapelWidth : lapelWidth
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric



  
 // FOR JACKET
 // FRONT
 frontButtons_Opts = [
    { optType:"FRONT BUTTONS", styleSelectionId: "", key: 'SINGLEBREASTED-ONE BUTTON', name: 'SINGLE BREASTED 1 BUTTON', imageSrc: webImageNamed('jacket/FrontButton/SINGLEbreasted1button.jpg') },
    { optType:"FRONT BUTTONS", styleSelectionId: "", key: 'SINGLEBREASTED-TWO BUTTON', name: 'SINGLE BREASTED 2 BUTTON', imageSrc: webImageNamed('jacket/FrontButton/SINGLEbreasted2button.jpg') },
    { optType:"FRONT BUTTONS", styleSelectionId: "", key: 'SINGLEBREASTED-THREE BUTTON', name: 'SINGLE BREASTED 3 BUTTON', imageSrc: webImageNamed('jacket/FrontButton/SINGLEbreasted3button.jpg') },
    { optType:"FRONT BUTTONS", styleSelectionId: "", key: 'DOUBLEBREASTED-2 WITH 1 FASTEN', name: 'DOUBLE BREASTED 2 WITH 1 FASTEN', imageSrc: webImageNamed('jacket/FrontButton/DOUBLEbreasted2button1fasten.jpg') },
    { optType:"FRONT BUTTONS", styleSelectionId: "", key: 'DOUBLEBREASTED-4 WITH 2 FASTEN', name: 'DOUBLE BREASTED 4 WITH 2 FASTEN', imageSrc: webImageNamed('jacket/FrontButton/DOUBLEbreasted4button2fasten.jpg') },
    { optType:"FRONT BUTTONS", styleSelectionId: "",  key: 'DOUBLEBREASTED-6 WITH 2 FASTEN', name: 'DOUBLE BREASTED 6 WITH 2 FASTEN', imageSrc: webImageNamed('jacket/FrontButton/DOUBLEbreasted6button2fasten.jpg') },
]
frontButtons_Step = () => {
    return {
        name: 'How would you like the front buttons?',
        key: this.STEP_KEYS.frontButtons,
        opts: this.frontButtons_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}

// LAPEL
lapel_Opts = [
    {optType:"LAPEL", styleSelectionId: "", key: 'NOTCH', name: 'NOTCH', imageSrc: webImageNamed('jacket/Lapel/NOTCH.jpg') },
    {optType:"LAPEL", styleSelectionId: "", key: 'PEAK', name: 'PEAK', imageSrc: webImageNamed('jacket/Lapel/PEAK.jpg') },
    {optType:"LAPEL", styleSelectionId: "",  key: 'SHAWL', name: 'SHAWL', imageSrc: webImageNamed('jacket/Lapel/SHAWL.jpg') },
]
lapel_Step = () => {
    return {
        name: 'How would you like the Lapel?',
        key: this.STEP_KEYS.lapel,
        opts: this.lapel_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// LAPEL WIDTH
lapelWidth_Opts = [
    {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'NARROW', name: 'NARROW – 5.5CM', imageSrc: webImageNamed('jacket/LapelWidth/Narrow.jpg') },
    {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'STANDARD', name: 'STANDARD', imageSrc: webImageNamed('jacket/LapelWidth/Standard.jpg') },
    {optType:"LAPEL WIDTH", styleSelectionId: "", key: 'WIDE', name: 'WIDE – 10CM', imageSrc: webImageNamed('jacket/LapelWidth/Wide.jpg') },
]
lapelWidth_Step = () => {
    return {
        name: 'Width of the Lapel',
        key: this.STEP_KEYS.lapelWidth,
        opts: this.lapelWidth_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// LOWER POCKETS
lowerPockets_Opts = [
    {optType:"LOWER POCKETS", styleSelectionId: "", key: 'STANDARD STRAIGHT WITH FLAPS', name: 'STANDARD STRAIGHT WITH FLAPS', imageSrc: webImageNamed('jacket/LowerPockets/STRAIGHTwithFLAPS.jpg') },
    {optType:"LOWER POCKETS", styleSelectionId: "", key: 'SLANTED WITH FLAPS', name: 'SLANTED WITH FLAPS', imageSrc: webImageNamed('jacket/LowerPockets/SLANTEDwithFLAPS.jpg') },
    {optType:"LOWER POCKETS", styleSelectionId: "", key: 'STANDARD TICKET POCKET', name: 'STANDARD TICKET POCKET', imageSrc: webImageNamed('jacket/LowerPockets/STRAIGHTFLAPSwithTP.jpg') },
    {optType:"LOWER POCKETS", styleSelectionId: "", key: 'SLANTED TICKET POCKET', name: 'SLANTED TICKET POCKET', imageSrc: webImageNamed('jacket/LowerPockets/SLANTEDFLAPSwithTP.jpg') },
]
lowerPockets_Step = () => {
    return {
        name: 'And Lower Pockets',
        key: this.STEP_KEYS.lowerPockets,
        opts: this.lowerPockets_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// VENT
vent_Opts = [
    {optType:"VENT", styleSelectionId: "", key: 'SINGLE', name: 'SINGLE', imageSrc: webImageNamed('jacket/VentStyle/SINGLE.jpg') },
    {optType:"VENT", styleSelectionId: "", key: 'DOUBLE', name: 'DOUBLE', imageSrc: webImageNamed('jacket/VentStyle/DOUBLE.jpg') },
]
vent_Step = () => {
    return {
        name: 'Choose Vents',
        key: this.STEP_KEYS.vent,
        opts: this.vent_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// SLEEVE BUTTONS
sleeveButtons_Opts = [
    {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'TWO', name: 'TWO', imageSrc: webImageNamed('jacket/SleeveButtons/TWO.jpg') },
    {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'THREE', name: 'THREE', imageSrc: webImageNamed('jacket/SleeveButtons/THREE.jpg') },
    {optType:"SLEEVE BUTTONS", styleSelectionId: "", key: 'FOUR', name: 'FOUR', imageSrc: webImageNamed('jacket/SleeveButtons/FOUR.jpg') },
]
sleeveButtons_Step = () => {
    return {
        name: 'How many Sleeve Buttons are good',
        key: this.STEP_KEYS.sleeveButtons,
        opts: this.sleeveButtons_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// LINING
lining_Opts = [
    {optType:"LINING", styleSelectionId: "", key: 'FULL', name: 'FULL', imageSrc: webImageNamed('jacket/LininigOption/FULLlining.jpg') },
    {optType:"LINING", styleSelectionId: "", key: 'HALF', name: 'HALF', imageSrc: webImageNamed('jacket/LininigOption/HALFlining.jpg') },
    {optType:"LINING", styleSelectionId: "", key: 'QUARTER', name: 'QUARTER', imageSrc: webImageNamed('jacket/LininigOption/QUARTERlining.jpg') },
]
lining_Step = () => {
    return {
        name: 'Do you like Full Lining',
        key: this.STEP_KEYS.lining,
        opts: this.lining_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}


// MONOGRAM
monogram_Opts = [
    {optType:"MONOGRAM", styleSelectionId: "", key: 'COLLAR FELT', name: 'COLLAR FELT', imageSrc: webImageNamed('jacket/monogram/CollarFelt.jpg'), customInput:"" },
    {optType:"MONOGRAM", styleSelectionId: "", key: 'ABOVE INNER LEFT BREAST POCKET', name: 'ABOVE INNER LEFT BREAST POCKET', imageSrc: webImageNamed('jacket/monogram/AboveInnerPocket.jpg'), customInput:"" },
]
monogram_Step = () => {
    return {
        name: 'If and where you would like to have monogram',
        key: this.STEP_KEYS.monogram,
        opts: this.monogram_Opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1',
        skipable: true,
        paddingLess: false,
        boldText: false,
        needsCustomInput: true
    }
}

// TONE
toneOpts = [
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.allSeasons, name: 'ALL SEASONS', imageSrc: webImageNamed('jacket/FABRICS/AllSeason.jpg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.winter, name: 'AUTUMN & WINTER', imageSrc: webImageNamed('jacket/FABRICS/Winter.jpg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.spring, name: 'SPRING & SUMMER', imageSrc: webImageNamed('jacket/FABRICS/Spring.jpg') },
    {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.stripes, name: 'STRIPES', imageSrc: webImageNamed('jacket/FABRICS/Stripes.jpg') },
    // { key: 'other', name: 'Other', imageSrc: null },
]
toneStep = () => {
    // Change images here with respect to button choices
    return {
        name: 'What Color Tone are we looking for?',
        key: this.STEP_KEYS.tone,
        opts: this.toneOpts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '3by4',
        skipable: false,
        paddingLess: false,
        boldText: false
    }
}

fabric_ALLSEASONSOpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBM618A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBM618A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBM706A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBM706A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBP674A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBP674A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBP748A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBP748A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBQ810A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBQ810A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBS133A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBS133A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBS135A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBS135A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBS141A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBS141A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBS146A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBS146A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBS147A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBS147A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU069A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBU069A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU071A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DBU071A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DMB707A', imageSrc: webImageNamed('jacket/FABRICS/AllSeason/DMB707A.jpg') , price:null , company:''},
]
fabric_AUTUMNWINTEROpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBQ662A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBQ662A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBR128A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBR128A.jpg') , price:null  , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBT605A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT605A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBT606A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT606A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBT607A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT607A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBT612A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT612A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBT613A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT613A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBT618A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT618A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBT621A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBT621A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBU011A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBU011A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU012A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBU012A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU013A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBU013A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DBU014A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBU014A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '14', name: 'DBU015A', imageSrc: webImageNamed('jacket/FABRICS/AutumnWinter/DBU015A.jpg ') , price:null , company:''},
]
fabric_SPRINGSUMMEROpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBN890A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBN890A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBP737A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBP737A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBP739A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBP739A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBS179A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBS179A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBS233A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBS233A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBT1503', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBT1503.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBT586A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBT586A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBT587A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBT587A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBU059A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBU059A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBU086A', imageSrc: webImageNamed('jacket/FABRICS/SpringSummer/DBU086A.jpg') , price:null , company:''},
]
fabric_STRIPESOpts = [
    {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'DBL622A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBL622A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'DBL647A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBL647A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'DBL650A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBL650A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'DBL748A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBL748A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'DBP645A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBP645A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'DBP690A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBP690A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'DBP691A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBP691A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'DBP753A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBP753A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'DBS131A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBS131A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'DBS132A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBS132A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'DBU080A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBU080A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'DBU081A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBU081A.jpg') , price:null , company:''},
    {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'DBU083A', imageSrc: webImageNamed('jacket/FABRICS/Stripes/DBU083A.jpg') , price:null , company:''},
]



fabricImageForSelectedKey = (tone, key) => {
    let opts = null
    switch (tone) {
        case this.TONE_KEYS.winter: opts = this.fabric_AUTUMNWINTEROpts; break;
        case this.TONE_KEYS.allSeasons: opts = this.fabric_ALLSEASONSOpts; break;
        case this.TONE_KEYS.spring: opts = this.fabric_SPRINGSUMMEROpts; break;
        default: opts = this.fabric_STRIPESOpts; break;
    }
    let thisObj = opts.filter((obj) => {
        return obj.key === key
    })
    return webImageNamed(thisObj[0].imageSrc)
}


optsForTone = (tone) => {
    switch (tone) {
        case this.TONE_KEYS.winter: return this.fabric_AUTUMNWINTEROpts; 
        case this.TONE_KEYS.allSeasons: return this.fabric_ALLSEASONSOpts; 
        case this.TONE_KEYS.spring: return this.fabric_SPRINGSUMMEROpts; 
        default: return this.fabric_STRIPESOpts; 
    }
}

fabricStep = () => {
    // Change images here with respect to button choices

    // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

    let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.allSeasons

    let opts = this.optsForTone(thisTone)


    console.log('OPTS NOW =', opts.k)
    console.log( opts)


    return {
        name: 'Pick a Fabric',
        key: this.STEP_KEYS.fabric,
        opts: opts,
        selectedOptKey: null,
        isPhotoBox: true,
        photoRatio: '1by1'
    }
}
 




    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }



     

    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_FrontButtons
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_FrontButtons.imageSrc){
                mainImage = this.state.selected_FrontButtons.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc){
                fabricImage = this.state.selected_Fabric.imageSrc
            }
           
            let styleName = this.state.productType + "-" + makeid(3)
            
            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }


            let allSelelected = [this.state.selected_FrontButtons, this.state.selected_Lapel, this.state.selected_LapelWidth, this.state.selected_LowerPockets, 
                this.state.selected_Vent, this.state.selected_SleeveButtons, this.state.selected_Lining, this.state.selected_Monogram, this.state.selected_Tone, this.state.selected_Fabric]


                let finalPrice = this.basePrice
                let addedPriceObjs = [] 
                let addOnPrice = 0
    
                allSelelected.forEach( asel => {
                    if (asel != null) {
                        if (asel.price) {
                            finalPrice += asel.price
                            addOnPrice += asel.price
                            addedPriceObjs.push( { type: asel.optType, name: asel.name, price: asel.price })
                        }
                    }
    
                })


            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_FrontButtons = ")
            console.log(this.state.selected_FrontButtons)

            let style = {
                frontButtons: this.state.selected_FrontButtons,
                lapel: this.state.selected_Lapel,
                lapelWidth: this.state.selected_LapelWidth,
                lowerPockets: this.state.selected_LowerPockets,
                vent: this.state.selected_Vent,
                sleeveButtons: this.state.selected_SleeveButtons,
                lining: this.state.selected_Lining,
                monogram: this.state.selected_Monogram,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric

            }


            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val 
                if (ms[1].customInput){

                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)){
                    description += ""
                }else{
                    description += ","
                }


            })


            // style -> selectedSelections, type <-> productType
 
            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol, this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]

            // [
            //     {
            //       "frontButtons": {
            //         "optType": "FrontButton",
            //         "key": "DOUBLEBREASTED-2 WITH 1 FASTEN",
            //         "name": "DOUBLE BREASTED 2 WITH 1 FASTEN",
            //         "imageSrc": "https://tailorwise.s3.eu-west-2.amazonaws.com/concierge/dl1/jacket/FrontButton/DOUBLEbreasted2button1fasten.jpg"
            //       }
            //   ]

            //   [
            //     {
            //       "value": "NO",
            //       "styleSelectionId": "JweCl34A0O",
            //       "key": "BUTTON HOLE CONTRAST"
            //     }
            //   ]

            //   [
            //     {
            //       "value": "DOUBLE BREASTED 2 WITH 1 FASTEN",
            //       "styleSelectionId": "",
            //       "key": "FrontButton",
            //       "isConciergeSelection" : true,
            //       "imageSrc": "url"
            //     }
            //   ]


            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map( (obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric'){
                    fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone'){
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return { 
                    "key" : obj.optType, 
                    "value" : obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "") , 
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null, 
                    "isConciergeSelection" : true
                 }
            })

            concToTwSelections = concToTwSelections.filter( (obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials

            prod.styleSelections = concToTwSelections


            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            // let prodObj = {
            //     key: uniqId,
            //     styleName:  styleName,
            //     frontImage: mainImage,
            //     fabricImage: fabricImage,
            //     description: description,
            //     type: this.state.productType,
            //     basePrice: this.basePrice,
            //     price: finalPrice,
            //     addedPriceObjs: addedPriceObjs,
            //     selectedSelections: style
            // }

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

                            // selected_FrontButtons : frontButtons
                // selected_Lapel : lapel
                // selected_LapelWidth : lapelWidth
                // selected_LowerPockets : lowerPockets
                // selected_Vent : vent
                // selected_SleeveButtons : sleeveButtons
                // selected_Lining : lining
                // selected_Monogram : monogram
                // selected_Tone : tone
                // selected_Fabric : fabric

                // ns[stepKey] = itemKey

                // console.log("ITEM KEY =" + itemKey)
                // let firstOPT = this.frontButtons_Opts.filter( opt => { return (opt.key === itemKey)})


                // console.log("FIRST OPT")
                // console.log(firstOPT)


            switch (stepKey) {
                case this.STEP_KEYS.frontButtons: ns.selected_FrontButtons =  this.frontButtons_Opts.filter( opt => { return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lapel: ns.selected_Lapel = this.lapel_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lapelWidth: ns.selected_LapelWidth = this.lapelWidth_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lowerPockets: ns.selected_LowerPockets = this.lowerPockets_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.vent: ns.selected_Vent = this.vent_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.sleeveButtons: ns.selected_SleeveButtons = this.sleeveButtons_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.lining: ns.selected_Lining = this.lining_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter( opt => {return opt.key === itemKey})[0] ; if (customInput && customInput !== ""){ ns.selected_Monogram["customInput"] = customInput } ; break;
                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter( opt => {return opt.key === itemKey})[0]; break;
                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    jacketWizard = () => {


        // selected_FrontButtons : frontButtons
        // selected_Lapel : lapel
        // selected_LapelWidth : lapelWidth
        // selected_LowerPockets : lowerPockets
        // selected_Vent : vent
        // selected_SleeveButtons : sleeveButtons
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric

    
    // let productStep = this.productStep()
    let frontButtons_Step = this.frontButtons_Step()
    let lapel_Step = this.lapel_Step()
    let lapelWidth_Step = this.lapelWidth_Step()
    let lowerPockets_Step = this.lowerPockets_Step()
    let vent_Step = this.vent_Step()
    let sleeveButtons_Step = this.sleeveButtons_Step()
    let lining_Step = this.lining_Step()
    let monogram_Step = this.monogram_Step()
    let toneStep = this.toneStep()
    let fabricStep = this.fabricStep()

    let title = "Let's Create a Style"
    let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    let points = [
        'To make it easy we pick the cuts and tones before finding the fabric.',
        'We will measure you right after we have created all the needed styles.'
    ]

    return (<div >
        {/* <div ref={this.mainStepperDiv}></div> */}
        {(this.state.isComplete || this.state.isCancelled) ?
            <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            :
            <StepWizard className=' fadeIn'>
                {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
               {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12nlnlndknkd3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  

               <Step name={frontButtons_Step.name}  skipable={frontButtons_Step.skipable ?? false} stepKey={frontButtons_Step.key }     exitingSelectedKey={this.state.selected_FrontButtons ? this.state.selected_FrontButtons.key  : null }     opts={frontButtons_Step.opts} isFirst={true} isPhotoBox={frontButtons_Step.isPhotoBox} photoRatio={frontButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={frontButtons_Step.boldText ?? false}  boldText={frontButtons_Step.boldText ?? false} ></Step>
               <Step name={lapel_Step.name}         skipable={lapel_Step.skipable ?? false} stepKey={lapel_Step.key }                   exitingSelectedKey={this.state.selected_Lapel ? this.state.selected_Lapel.key         : null }            opts={lapel_Step.opts} isFirst={false} isPhotoBox={lapel_Step.isPhotoBox} photoRatio={lapel_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lapel_Step.boldText ?? false}  boldText={lapel_Step.boldText ?? false} ></Step>
               <Step name={lapelWidth_Step.name}    skipable={lapelWidth_Step.skipable ?? false} stepKey={lapelWidth_Step.key }         exitingSelectedKey={this.state.selected_LapelWidth ? this.state.selected_LapelWidth.key    : null }       opts={lapelWidth_Step.opts} isFirst={false} isPhotoBox={lapelWidth_Step.isPhotoBox} photoRatio={lapelWidth_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lapelWidth_Step.boldText ?? false}  boldText={lapelWidth_Step.boldText ?? false} ></Step>               
               <Step name={lowerPockets_Step.name}  skipable={lowerPockets_Step.skipable ?? false} stepKey={lowerPockets_Step.key }     exitingSelectedKey={this.state.selected_LowerPockets ? this.state.selected_LowerPockets.key  : null }     opts={lowerPockets_Step.opts} isFirst={false} isPhotoBox={lowerPockets_Step.isPhotoBox} photoRatio={lowerPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lowerPockets_Step.boldText ?? false}  boldText={lowerPockets_Step.boldText ?? false} ></Step>
               <Step name={vent_Step.name}          skipable={vent_Step.skipable ?? false} stepKey={vent_Step.key }                     exitingSelectedKey={this.state.selected_Vent ? this.state.selected_Vent.key          : null }             opts={vent_Step.opts} isFirst={false} isPhotoBox={vent_Step.isPhotoBox} photoRatio={vent_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={vent_Step.boldText ?? false}  boldText={vent_Step.boldText ?? false} ></Step>
               <Step name={sleeveButtons_Step.name} skipable={sleeveButtons_Step.skipable ?? false} stepKey={sleeveButtons_Step.key }   exitingSelectedKey={this.state.selected_SleeveButtons ? this.state.selected_SleeveButtons.key : null }    opts={sleeveButtons_Step.opts} isFirst={false} isPhotoBox={sleeveButtons_Step.isPhotoBox} photoRatio={sleeveButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={sleeveButtons_Step.boldText ?? false}  boldText={sleeveButtons_Step.boldText ?? false} ></Step>
               <Step name={lining_Step.name}        skipable={lining_Step.skipable ?? false} stepKey={lining_Step.key }                 exitingSelectedKey={this.state.selected_Lining ? this.state.selected_Lining.key        : null }           opts={lining_Step.opts} isFirst={false} isPhotoBox={lining_Step.isPhotoBox} photoRatio={lining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lining_Step.boldText ?? false}  boldText={lining_Step.boldText ?? false} ></Step>
               <Step name={monogram_Step.name}   needsCustomInput={true}   skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key }             exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key      : null }         opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={monogram_Step.boldText ?? false}  boldText={monogram_Step.boldText ?? false} ></Step>
               <Step name={toneStep.name}           skipable={toneStep.skipable ?? false} stepKey={toneStep.key }                       exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key          : null }             opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={toneStep.boldText ?? false}  boldText={toneStep.boldText ?? false} ></Step>
               <Step name={fabricStep.name}         skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key }                   exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key        : null }           opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={true} paddingLess={fabricStep.boldText ?? false}  boldText={fabricStep.boldText ?? false} ></Step>

            </StepWizard>
        }
        {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    </div>
    )
}



//     jacketWizard = () => {


//         // selected_FrontButtons : frontButtons
//         // selected_Lapel : lapel
//         // selected_LapelWidth : lapelWidth
//         // selected_LowerPockets : lowerPockets
//         // selected_Vent : vent
//         // selected_SleeveButtons : sleeveButtons
//         // selected_Lining : lining
//         // selected_Monogram : monogram
//         // selected_Tone : tone
//         // selected_Fabric : fabric

    
//     // let productStep = this.productStep()
//     let frontButtons_Step = this.frontButtons_Step()
//     let lapel_Step = this.lapel_Step()
//     let lapelWidth_Step = this.lapelWidth_Step()
//     let lowerPockets_Step = this.lowerPockets_Step()
//     let vent_Step = this.vent_Step()
//     let sleeveButtons_Step = this.sleeveButtons_Step()
//     let lining_Step = this.lining_Step()
//     let monogram_Step = this.monogram_Step()
//     let toneStep = this.toneStep()
//     let fabricStep = this.fabricStep()

//     let allSteps = [frontButtons_Step, lapelWidth_Step, lapelWidth_Step, lowerPockets_Step, vent_Step, sleeveButtons_Step, lining_Step, monogram_Step, toneStep, fabricStep]

//     // console.log('Name =',frontStep.name)
//     // console.log('Key =',frontStep.key)
//     // console.log('Opts =',frontStep.opts)

//     // createUploadChooser

//     let title = "Let's Create a Style"
//     let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
//     let points = [
//         'To make it easy we pick the cuts and tones before finding the fabric.',
//         'We will measure you right after we have created all the needed styles.'
//     ]

//     return (<div >
//         {/* <div ref={this.mainStepperDiv}></div> */}
//         {(this.state.isComplete || this.state.isCancelled) ?
//             <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
//             :
//             <StepWizard className=' fadeIn'>
//                 {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
//                {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={allSteps[0].key + "123"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  

//                 {allSteps.map((step, index) => {
//                     return <Step key={"bhsbhjsh" + index} name={step.name} skipable={step.skipable ?? false} stepKey={step.key } exitingSelectedKey={this.state[step.key]} opts={step.opts} isFirst={index == 0} isPhotoBox={step.isPhotoBox} photoRatio={step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={index == (allSteps.length - 1)} paddingLess={step.boldText ?? false}  boldText={step.boldText ?? false} ></Step>
//                 })}
//             </StepWizard>
//         }
//         {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
//     </div>
//     )
// }




    // jacketWizard = () => {


    //         // selected_FrontButtons : frontButtons
    //         // selected_Lapel : lapel
    //         // selected_LapelWidth : lapelWidth
    //         // selected_LowerPockets : lowerPockets
    //         // selected_Vent : vent
    //         // selected_SleeveButtons : sleeveButtons
    //         // selected_Lining : lining
    //         // selected_Monogram : monogram
    //         // selected_Tone : tone
    //         // selected_Fabric : fabric

        
    //     // let productStep = this.productStep()
    //     let frontButtons_Step = this.frontButtons_Step()
    //     let lapel_Step = this.lapel_Step()
    //     let lapelWidth_Step = this.lapelWidth_Step()
    //     let lowerPockets_Step = this.lowerPockets_Step()
    //     let vent_Step = this.vent_Step()
    //     let sleeveButtons_Step = this.sleeveButtons_Step()
    //     let lining_Step = this.lining_Step()
    //     let monogram_Step = this.monogram_Step()
    //     let toneStep = this.toneStep()
    //     let fabricStep = this.fabricStep()

    //     // console.log('Name =',frontStep.name)
    //     // console.log('Key =',frontStep.key)
    //     // console.log('Opts =',frontStep.opts)

    //     // createUploadChooser

    //     let title = "Let's Create a Style"
    //     let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    //     let points = [
    //         'To make it easy we pick the cuts and tones before finding the fabric.',
    //         'We will measure you right after we have created all the needed styles.'
    //     ]

    //     return (<div >
    //         {/* <div ref={this.mainStepperDiv}></div> */}
    //         {(this.state.isComplete || this.state.isCancelled) ?
    //             <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
    //             :
    //             <StepWizard className=' fadeIn'>
    //                 {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
    //                {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={frontStep.key} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  
    //                 <Step completeObj={this.state} name={frontStep.name} skipable={false} stepKey={frontStep.key} exitingSelectedKey={this.state.selected_FrontButtons} opts={frontStep.opts} isFirst={this.state.editingProduct} isPhotoBox={frontStep.isPhotoBox} photoRatio={frontStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step>
    //                 <Step name={toneStep.name} skipable={false} stepKey={toneStep.key} exitingSelectedKey={this.state.selected_Tone} opts={toneStep.opts} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={fabricStep.name} isFabric={true} skipable={false} stepKey={fabricStep.key} exitingSelectedKey={this.state.selected_Fabric} opts={fabricStep.opts} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={buttonStep.name} skipable={false} stepKey={buttonStep.key} exitingSelectedKey={this.state.selected_Lapel} opts={buttonStep.opts} paddingLess={false} isPhotoBox={buttonStep.isPhotoBox} photoRatio={buttonStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={collarStep.name} skipable={false} stepKey={collarStep.key} exitingSelectedKey={this.state.selected_LapelWidth} opts={collarStep.opts} paddingLess={false} isPhotoBox={collarStep.isPhotoBox} photoRatio={collarStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={backStep.name} skipable={false} stepKey={backStep.key} exitingSelectedKey={this.state.selected_Back} opts={backStep.opts} paddingLess={false} isPhotoBox={backStep.isPhotoBox} photoRatio={backStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={pantStep.name} skipable={false} stepKey={pantStep.key} exitingSelectedKey={this.state.selected_PantStyle} opts={pantStep.opts} isLast={false} paddingLess={false} isPhotoBox={pantStep.isPhotoBox} photoRatio={pantStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //                 <Step name={fitStep.name} boldText={true} skipable={false} stepKey={fitStep.key} exitingSelectedKey={this.state.selected_Fitting} opts={fitStep.opts} isLast={true} paddingLess={false} isPhotoBox={fitStep.isPhotoBox} photoRatio={fitStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected} ></Step>
    //             </StepWizard>
    //         }
    //         {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    //     </div>
    //     )
    // }


    

    render() {
        return (
            this.jacketWizard()
        )
    }

}


export default WizardForJacket
