import { PRODUCT_TYPE, webImageNamed } from '../utilities/utils'
import { currencySymbol } from '../utilities/clientInfo'
import { C_Product, Product } from '../utilities/classes'


const makeNewProductWith = (type, objectId, name, price, description, mainImageUrl, images) => {
    // let prod = new C_Product(objectId, false, type, objectId, name, mainImageUrl, secondImageUrl, images, [], [], "", description,
    // false, null, true, false, null, false, [], currencySymbol, price, 0, 0, 0, price, [], "Style" + name, mainImageUrl, secondImageUrl)

    let prodd = Product.init()
    prodd.type = type
    prodd.objectId = objectId
    prodd.name = name
    prodd.price = price
    prodd.description = description
    prodd.imageUrl = mainImageUrl
    prodd.imagesUrls = images
    prodd.isBespoke = false
    prodd.isMTO = true
    prodd.currency = currencySymbol

    return prodd
    
}

export const suprise = makeNewProductWith(PRODUCT_TYPE.overCoat, 
    "sbjksbjsbjdjkbjdbdk44bjdb",                                    
    "TEST",                  
    1,                                            
    "A test product. Shipping will also not apply on order containing this product.",   
    webImageNamed("sample.jpeg"),         
    [                                               
        webImageNamed("sample.jpeg"),     
    ]   
)



export const prod1 = makeNewProductWith(PRODUCT_TYPE.overCoat, 
    "vjhdjhdv3r346dd",                                    
    "Dover Overcoat",                  
    900,                                            
    "Navy blue wool/cashmere. Double breasted 6/2 buttons. Peak lapel/Military collar. Outer patch pockets with flaps. Single vents with button tabs. Folded cuffs. Belt on back.",   
    webImageNamed("RTW/BlueDBWoolOvercoat/BOC1.jpg"),         
    [                                               
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC1.jpg"),     
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC3.jpg"),     
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC.jpg"),     
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC7.jpg"),     
    ]   
)

export const prod1Copy = makeNewProductWith(PRODUCT_TYPE.overCoat, 
    "vjhdjhdvd565r7d",                                    
    "Lincoln Overcoat",                  
    900,                                            
    "Midnight blue wool/cashmere. Single breasted 3 buttons. Peak lapel. Slant welt pockets. Single vents. Buttons.",   
    webImageNamed("RTW/BlueDBWoolOvercoat/BOC4.jpg"),         
    [                                               
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC4.jpg"),     
        webImageNamed("RTW/BlueDBWoolOvercoat/BOC5.jpg"),     
    ]   
)



export const prod2 = makeNewProductWith(PRODUCT_TYPE.overCoat, 
    "dvjhd4t84t765vhdvj",                                    
    "Norbury Overcoat",                  
    800,                                            
    "Tan wool/cashmere. Double breasted 6/2 buttons. Peak lapel. Normal pockets with ticket pocket. Single vents. 4 sleeve button/buttonhole.",   
    webImageNamed("RTW/TanDBWoolOvercoat/TWOC1.jpg"),         
    [                                               
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC1.jpg"),     
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC3.jpg"),     
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC.jpg"),
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC2.jpg"),
    ]   
)

export const prod2Copy = makeNewProductWith(PRODUCT_TYPE.overCoat, 
    "dvjhdvhdv4874987j",                                    
    "Cromby Overcoat",                  
    800,                                            
    "Brown – 100% wool. Double breasted 6/2 buttons. Peak lapel. Normal pockets with ticket pocket. 4 sleeve button/buttonhole.",   
    webImageNamed("RTW/TanDBWoolOvercoat/TWOC4.jpg"),         
    [                                               
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC4.jpg"),
        webImageNamed("RTW/TanDBWoolOvercoat/TWOC5.jpg"),
    ]   
)

export const prod3 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "dkjbdkjbdkjbdj",                                    
    "Navy Blue Jacket",                  
    500,                                            
    `
    Navy blue worsted wool. Single breasted 2 buttons. Peak lapel (9.8cm). Slanted pocket with ticket pocket. Double vents. Mother of Pearl Buttons. Slanted 4 buttons. Straight buttonholes. Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. 
    `,   
    webImageNamed("RTW/NavyBlueSuit/NAVYSUIT.jpg"),         
    [                                               
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT.jpg"),
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT1.jpg"),
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT2.jpg")
    ]   
)
export const prod4 = makeNewProductWith(PRODUCT_TYPE.waistCoat, 
    "skjbskjvdjdvjkd",                                    
    "Navy Blue Vest",                  
    150,                                            
    "Navy blue worsted wool. Double breasted 6/3 buttons. Shawl lapel. Lower welt pockets. Square bottom. Mother of Pearl Buttons. Highbury Vest (Grey flannel waistcoat). Grey mid-weight flannel wool. Double breasted 6/3 buttons. Shawl lapel. Lower welt pockets. Square bottom. Mother of Pearl Buttons.",   
    webImageNamed("RTW/NavyBlueSuit/NAVYSUIT1.jpg"),         
    [                                               
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT1.jpg"),     
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT.jpg"),     
    ]   
)
export const prod5 = makeNewProductWith(PRODUCT_TYPE.pant, 
    "djkbdkjdbjdbkd",                                    
    "Navy Blue pant",                  
    250,                                            
    "Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket.",   
    webImageNamed("RTW/NavyBlueSuit/NAVYSUIT2.jpg"),         
    [                                               
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT2.jpg"),
        webImageNamed("RTW/NavyBlueSuit/NAVYSUIT1.jpg")
    ]   
)
export const prod6 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "kjvfjvfjvfff",                                    
    "Grey Jacket",                  
    500,                                            
    "Grey flannel – 15oz. Single breasted 2 buttons. Peak lapel. Patch pockets. Double vents. 4 straight Buttons/buttonholes.",   
    webImageNamed("RTW/GreySuit/GSUIT.jpg"),         
    [                                               
        webImageNamed("RTW/GreySuit/GSUIT.jpg"),     
        webImageNamed("RTW/GreySuit/GSUIT1.jpg"),     
        webImageNamed("RTW/GreySuit/GSUIT2.jpg"),
        webImageNamed("RTW/GreySuit/GSUIT3.jpg"),
        webImageNamed("RTW/GreySuit/GSUIT4.jpg"),
    ]   
)
export const prod7 = makeNewProductWith(PRODUCT_TYPE.waistCoat, 
    "skjkvdjdvkjdvkvjd",                                    
    "Grey Vest",                  
    150,                                            
    "Grey Suit Collections - Vest Only",   
    webImageNamed("RTW/GreySuit/GSUIT3.jpg"),         
    [                                               
        webImageNamed("RTW/GreySuit/GSUIT3.jpg"),     
        webImageNamed("RTW/GreySuit/GSUIT1.jpg"),     
        webImageNamed("RTW/GreySuit/GSUIT2.jpg"),
    ]   
)
export const prod8 = makeNewProductWith(PRODUCT_TYPE.pant, 
    "vdhvjdhvdhvdjhvd",                                    
    "Grey pant",                  
    250,                                            
    "Grey Suit Collections - pant Only",   
    webImageNamed("RTW/GreySuit/GSUIT5.jpg"),         
    [                                               
        webImageNamed("RTW/GreySuit/GSUIT5.jpg"),     
    ]   
)
export const prod9 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "dvhdvjhdvdvjdhvjh", 
    "Burgundy Corduroy Suit Collections - Jacket Only",                                   
    350,           
    "Burgundy corduroy. Single breasted 2 buttons. Notch lapel. Patch pockets. Double vents. Buttons.",                                               
    webImageNamed("RTW/BurgundySuit/BURSUIT1.jpg"),         
    [                                               
        webImageNamed("RTW/BurgundySuit/BURSUIT1.jpg"),     
        webImageNamed("RTW/BurgundySuit/BURSUIT.jpg"),     
    ]   
)
export const prod10 = makeNewProductWith(PRODUCT_TYPE.pant, 
    "djkdbkjdbkdbdjvwskvskjv",                                    
    "Corduroy pant",                  
    250,                                            
    "Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket.",   
    webImageNamed("RTW/BurgundySuit/BURSUIT.jpg"),         
    [                                               
        webImageNamed("RTW/BurgundySuit/BURSUIT.jpg"),     
        webImageNamed("RTW/BurgundySuit/BURSUIT1.jpg"),     
    ]   
)
export const prod11 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "vjhdvhdvjhdhdvjsv",                                    
    "Blue Double Breasted Jacket",                  
    400,                                            
    "Navy Blue virgin lambswool. Double breasted 6 buttons with 2 fastening. Gold blue buttons. 9.8in peak lapels. 4 flat sleeve buttons. Double vents.",   
    webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB2.jpg"),         
    [                                               
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB2.jpg"),     
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB7.jpg"),     
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB5.jpg"),     
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB6.jpg"),     
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB3.jpg"),     
        webImageNamed("RTW/BlueDoubleBreastedBlazer/BDBB.jpg"),     
    ]   
)
export const prod12 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "mfhkjfhjhdjkvdkjdv",                                    
    "Blue Plaid Jacket",                  
    450,                                            
    "Wool/Cashmere. Single breasted 2 buttons. Peak lapel. Patch pockets. Double vents. Buttons. ",   
    webImageNamed("RTW/BluePlaidJacket/BLPJ2.jpg"),         
    [                                               
        webImageNamed("RTW/BluePlaidJacket/BLPJ2.jpg"),     
        webImageNamed("RTW/BluePlaidJacket/BLPJ1.jpg"),     
    ]   
)
export const prod13 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "jfbjfbkjcjbcjdkjdjk",                                    
    "Brown Plaid Jacket",                  
    450,                                            
    "Brown Plaid Jacket",   
    webImageNamed("RTW/BrownPlaidJacket/BPJ3.jpg"),         
    [                                               
        webImageNamed("RTW/BrownPlaidJacket/BPJ3.jpg"),     
        webImageNamed("RTW/BrownPlaidJacket/BPJ.jpg"),     
    ]   
)
export const prod14 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "kjkjfvkjfvkjvfkjfvkj",                                    
    "Brown Tattersall Jacket",                  
    350,                                            
    "Brown tattersall. Single breasted 2 buttons. Peak lapel. Patch pockets. Double vents. 4 straight Buttons/buttonholes.",   
    webImageNamed("RTW/BrownTattersallJacket/BTJ.jpg"),         
    [                                               
        webImageNamed("RTW/BrownTattersallJacket/BTJ.jpg"),     
        webImageNamed("RTW/BrownTattersallJacket/BTJ2.jpg"),     
        webImageNamed("RTW/BrownTattersallJacket/BTJ3.jpg"),     
        webImageNamed("RTW/BrownTattersallJacket/BTJ5.jpg"),     
    ]   
)
export const prod15 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "jhvdhjdvhjdvjhdvjd",                                    
    "Camel Soft Suede Jacket",                  
    450,                                            
    "Camel Soft Suede Jacket",   
    webImageNamed("RTW/CamelSuedeJacket/CSJ.jpg"),         
    [                                               
        webImageNamed("RTW/CamelSuedeJacket/CSJ.jpg"),     
        webImageNamed("RTW/CamelSuedeJacket/CSJ4.jpg"),     
        webImageNamed("RTW/CamelSuedeJacket/CSJ3.jpg"),     
        webImageNamed("RTW/CamelSuedeJacket/CSJ2.jpg"),     
    ]   
)
export const prod16 = makeNewProductWith(PRODUCT_TYPE.pant, 
    "dvhjdvjhdvhdvjhdv",                                    
    "Brown Flannel Trousers",                  
    250,                                            
    "Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket.",   
    webImageNamed("RTW/BrownFlannelTrousers/BROWNT.jpg"),         
    [                                               
        webImageNamed("RTW/BrownFlannelTrousers/BROWNT.jpg"),     
    ]   
)
export const prod17 = makeNewProductWith(PRODUCT_TYPE.pant, 
    "jdjvddvkjdvkjd",                                    
    "Grey Flannel Trouser",                  
    300,                                            
    "Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket. Brown flannel trousers. Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket.",   
    webImageNamed("RTW/GreyFlannelTrousers/GreyT.jpg"),         
    [                                               
        webImageNamed("RTW/GreyFlannelTrousers/GreyT.jpg"),     
    ]   
)
export const prod18 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "hvkjdvkjdvkjvdj",                                    
    "Chinos",                  
    200,                                            
    "Double pleated trousers. Side buckle adjusters. 5cm cuff. 5cm high waistband. Single right back pocket.",   
    webImageNamed("RTW/ChinoswithColors/CHINO1.jpg"),         
    [                                               
        webImageNamed("RTW/ChinoswithColors/CHINO1.jpg"),     
        webImageNamed("RTW/ChinoswithColors/CHINO.jpg"),     
    ]   
)
export const prod19 = makeNewProductWith(PRODUCT_TYPE.jacket, 
    "kjbdjkjkdvdkjvdk",                                    
    "Polo T-Shirt",                  
    120,                                            
    "Classic long sleeve Polo T-Shirt",   
    webImageNamed("RTW/PoloTshirt/POLO1.jpg"),         
    [                                               
        webImageNamed("RTW/PoloTshirt/POLO1.jpg"),     
        webImageNamed("RTW/PoloTshirt/POLO2.jpg"),     
    ]   
)


export const allProds = [
    prod1, prod1Copy, prod2, prod2Copy, prod3, prod4, prod5, prod6, prod7, prod8, prod9, prod10, prod11, prod12, prod13, prod14, prod15, prod16, prod17, prod18, prod19
]