import React, { useEffect, useState } from 'react'
import { Box, Columns, Button, Tag, Image, Modal, Heading, Table, Dropdown } from 'react-bulma-components/dist';
import { Product, Order } from '../utilities/classes'
import { getCurrentCustomer } from '../utilities/Login'
import { standardDeliveryTime, freeShippingThreshold, standardPaidShippingPrice } from '../utilities/clientInfo'


import { dateToDateString } from '../utilities/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import cogoToast from 'cogo-toast'
import PaymentWrapper from './PaymentWrapper'
import PaypalPaymentPage from './PaypalPaymentPage'
import ColumnAux from './ColumnAux'
// import Logo from '../assets/logoClientNav.png'
import OrderActionForm from './OrderActionForm'
import MeasurementBox from './MeasurementBox'
import OrderBox from './OrderBox'
import OrderModal from './OrderModal'


const CustomerModal = (props) => {


    let [orders, setOrders] = useState([])
    let [selectedOrder, setSelectedOrder] = useState(null)
    let [noOrder, setNoOrder] = useState(false)




    useEffect(()=> {
        getCustomerOrders(props.customer)
    }, [props.customer])

    const getCustomerOrders = (cust) => {
        console.log('SEARCHING FOR CUSTOMER ORDERS')
        Order.getAllForCustomer(cust.objectId, (succ, orders, errMsg) => {
            console.log('SEARCHED FOR CUSTOMER ORDERS')
            console.log(orders)

            if (succ) {
                setOrders(orders)
                if (orders.length === 0){
                    setNoOrder(true)
                }
            } else {
                cogoToast.warn("Could not fetch orders. Check internet connection.")
                console.log(errMsg)
            }
        })
    }




    const measures = (cust) => {

        if (cust.conciergeMeasurements === null) {
            return null
        } else {

            let measuresCopy = { ...cust.conciergeMeasurements }
            let measureObj = {
                measures: measuresCopy,
                description: "For All Products",
                key: "NSKJBKJBDJDJDBD"
            }
            return (<div key={measureObj.key} className='marginTop10'> <MeasurementBox measure={measureObj} isAtProfile={true} /> <br /> </div>)

        }


    }


    const viewPressed = (order) => {
        setSelectedOrder(order)
    }


  const orderList = () => {
        return (
            <ColumnAux className=' fadeIn' isMiddle={false} size={12}>

                { noOrder ? <Heading size={6} className='has-text-centered has-text-grey-light'> No Orders </Heading> : null     }


                {
                    orders.length > 0 ?

                        <div>
                                <p className='has-text-grey  marginBottom5'>Orders </p>
                            {
                                orders.map((ord) => {
                                    return <OrderBox key={ord.objectId} order={ord} viewPressed={viewPressed} />
                                })
                            }
                            
                        </div>

                        :

                        null
                }



            </ColumnAux>

        )
    }


    const closeSelectedEntities = () => {
        setSelectedOrder(null)
    }

    const orderUpdated = (updatedOrder) => {
        let ords = [...orders]
        let objIndex = ords.findIndex(obj =>
            obj.objectId === updatedOrder.objectId
        )
        ords[objIndex] = updatedOrder
        setOrders(ords)
    }

    const orderModal = () => {
        return <OrderModal order={selectedOrder} closed={closeSelectedEntities} orderUpdated={orderUpdated} isAdmin={true} />
    }



    const customerModal = (customer) => {

        console.log(`ORDER MODAL FIRED WITH ${customer.name}`)

        return (
            <Modal show={true} closeOnBlur={true} showClose={true} onClose={props.closed} >
                <Modal.Content className='is-padingless'>

                { selectedOrder ? orderModal() : null}

                    <Box className='is-radiusless is-padingless fadeIn'>
                        {/* { props.isAdmin ? <Button className='is-pulled-right marginBottom20 is-light is-info is-small' onClick={actionPressed} color="light" > Edit Actions </Button>  : null }   */}

                        <div>
                            <Heading size={5}> {customer.name}</Heading>
                        </div>
                        <br />
                        <div>
                            <Columns className='is-mobile is-size-7'>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Email </p>
                                    <p>{customer.email1 ? customer.email1 : null}</p>
                                </Columns.Column>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Phone </p>
                                    <p>{customer.phone1 ? customer.phone1 : null}</p>
                                </Columns.Column>
                                <Columns.Column className=''>
                                    <p className='has-text-grey  marginBottom5'> Country </p>
                                    <p>{customer.country ? customer.country : null}</p>
                                </Columns.Column>
                            </Columns>
                            <br />
                        </div>


                        <div>
                            <p className='has-text-grey  marginBottom5'>Address </p>
                            <div className="type60">
                                <p>{customer.address ? customer.address : null}</p>
                                <p>{customer.state ? 'State/City: ' + customer.state : null}</p>
                                <p>{customer.postal ? 'Postal: ' + customer.postal : null}</p>
                                <p>{customer.country ? 'Country: ' + customer.country : null}</p>
                            </div>
                            <br />
                        </div>

                        {customer.conciergeMeasurements ?
                            <>
                                <br />
                                <p className='has-text-grey  marginBottom5'>Measurements </p>
                                {measures(customer)}
                            </>

                            : null}

                        <div>
                            {orderList()}
                        </div>




                    </Box>
                </Modal.Content >
            </Modal >
        )
    }






    const page = () => {

        return <> </>
    }

    return (
        customerModal(props.customer)
    )
}



export default CustomerModal
