import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faArrowLeft, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faPhone, faPhoneAlt, faSms, faMailBulk, faEnvelope, faSurprise, faSmileBeam, faSmile, faRibbon, faRainbow, faHatCowboy, faAirFreshener, faBirthdayCake, faHatCowboySide } from '@fortawesome/free-solid-svg-icons'
// import Logo from '../assets/tw-logo-140x30.svg'
// import Logo from '../assets/logoClient.png'
// import Logo from '../assets/logoClientNav.png'

import SignIn from './SignIn'
import SignUp from './SignUp'


import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'
import ChooseBox from './ChooseBox'
import StyleChooserWizard from './StyleChooserWizard'
import MeasurementsWizard from './MeasurementsWizard'
import StyleBox from './StyleBox'
import StyleCard from './StyleCard'
import OrderBox from './OrderBox'
import OrderModal from './OrderModal'


import ModalFullPage from './ModalFullPage'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Tag, Pagination, Dropdown
} from 'react-bulma-components/dist'

import { Order, Product, Customer } from '../utilities/classes'
import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import cogoToast from 'cogo-toast'
import { getCurrentAdmin, saveCurrentAdmin, removeCurrentAdmin } from '../utilities/Login'
import SelectBox from './SelectBox'



const AdminProducts = (props) => {

    let [images, setImages] = useState([])
    let [selectedBoxKey, setSelectedBoxKey] = useState(null)


    const boxSelected = () => {

    }

   const photoBox = () => {
        const steps = props.opts.map((opt) => {
            return <SelectBox key={opt.key}  name={opt.key} isFabric={true} company={opt.company} price={opt.price} isSelected={selectedBoxKey === opt.key} isFirst={props.isFirst} isLast={props.isLast} paddingLess={props.paddingLess ?? false} isPhotoBox={props.isPhotoBox} photoRatio={props.photoRatio} imageSrc={opt.imageSrc} caption={opt.name} selectedPressed={boxSelected} />
        })

        return (
            <div className='myFlexContainer '>
                {steps}
            </div>
        )
    }



    const styleView = () => {

        return (
        <div className=' measurementsContainer'>
            {/* <Heading className='has-text-centered has-text-weight-normal padHorizontal10 stickTop' size={5}> {props.name} </Heading> */}
            <div className=''>
                {photoBox()}
            </div>

        </div>
    )}



    const page = () => {
        return (
            <div>
                <ColumnAux isMiddle={true} size={8}>
                    <Heading size={6} > This is Products </Heading>
                </ColumnAux>
            </div>
        )
    }

    return page()

}

export default AdminProducts