import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button, Columns, Heading } from 'react-bulma-components/dist'
import { Formik, Form, Field } from 'formik'
import { getCurrentCustomer, saveCurrentCustomer } from '../utilities/Login'
import { Customer, Order, Product } from '../utilities/classes'
import { hasShop, currencySymbol, currencyCode } from '../utilities/clientInfo'
import { newOrderNo, randomAlphabets } from '../utilities/utils'
import CountrySelect from './CountrySelect'

import cogoToast from 'cogo-toast'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'



const BillingForm = (props) => {

    let currentCust = props.customer ?? null

    let billingAddressValues = {
        name: currentCust ? currentCust.name : "",
        address: currentCust ? currentCust.address : "",
        state: currentCust ? currentCust.state : "",
        country: currentCust ? currentCust.country : "",
        pincode: currentCust ? currentCust.pincode : "",
        phone: currentCust ? currentCust.phone1 : "",
        note: "",
    }


    const validateName = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 3) {
            error = 'Please enter full Name';
        }
        return error;
    }


    const validateAddress = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validateCityState = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 3) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validateCountry = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validatePincode = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }

    const validatePhone = (value) => {
        let error;

        if (!value) {
            error = 'Required';
        } else if (value.length < 5) {
            error = 'Please enter full info';
        }
        return error;
    }


    function saveOrMakeOrder(values, callback) {

        let cust = getCurrentCustomer()


        if (cust === null){
            callback({ success: false, message: 'You Should Sign In Before going ahead' })
            props.signInViaModal()
            return 
        }


        if (props.products == null ){
            if ( hasShop  ) {
                cogoToast.warn('Add Styles from Shop or Concierge to make order.')
            }else{
                cogoToast.warn('Add Styles from Concierge to make order.')
            }
            callback({ success: false, message: 'No Products to create Order ' })
            return
        }


        if (props.products.length === 0 ){
            if ( hasShop  ) {
                cogoToast.warn('Add Styles from Shop or Concierge to make order.')
            }else{
                cogoToast.warn('Add Styles from Concierge to make order.')
            }           
            callback({ success: false, message: 'No Products to create Order ' })
            return
        }


        // console.log("MEASUREMENTS BEFOR CHECK")
        // console.log(props.measurements)


        // console.log("CUSTOMER CONCIERGE MEASUREMENTS BEFOR CHECK")
        // console.log(cust.conciergeMeasurements)

        if (!props.measurements.length &&  cust.conciergeMeasurements == null ) {
            cogoToast.warn('Please add your measurements.')
            callback({ success: false, message: 'No Measurements to create Order ' })
            return
        }


        // take styles, measurements and totalAmount from props

        // create customer


        // create Order


        // or Create Order and show Stripe Payment 

        // setTimeout(() => {
        //     console.log('Order Made. Going To payment ')

        //     props.startPayment(true)

        //     callback({ success: true, message: 'Order Made. Going To payment ' })
        // }, 1600);


        let thisCust = Customer.copyFrom(cust)

        // console.log("THIS CUST MMs before save")
        // console.log(thisCust.conciergeMeasurements)




        thisCust.name = values.name ?? ""
        thisCust.address = values.address ?? ""
        thisCust.state = values.state ?? ""
        thisCust.pincode = values.pincode ?? ""
        thisCust.country = values.country ?? ""
        thisCust.phone1 = values.phone ?? ""

        thisCust.update((succ, errMsg) => {
            if (succ === true) {
                // currentCust = thisCust
                cogoToast.success("Address is Updated")
                // saveCurrentCustomer(thisCust)


            } else {
                cogoToast.warn("Could not update address. " + errMsg)
                // callback({ success: false, message: 'Could not update address. ' + errMsg })
            }

        })


        // if (props.order){
        //     // Update this order

        //     return
        // }

        // Or Create new
        console.log("A ")


                // CREATE ORDER NOW ... 

                let orderNo = newOrderNo()

                console.log("B ")


                let deliveryAddress = `Name: ${thisCust.name ?? ""} . Address: ${thisCust.address ?? ""}, ${thisCust.state ?? ""}. Postal: ${thisCust.postal ?? ""}. Country:  ${thisCust.country ?? ""}. Phone: ${thisCust.phone1 ?? ""}`

                console.log("C ")

                let orderMeasures = props.measurements.length ? props.measurements[0] : null

                console.log("D ")

                if (orderMeasures !== null){
                    if (!orderMeasures.length){
                        console.log("E ")

                        orderMeasures = thisCust.conciergeMeasurements
                    }
                }




                // delete orderMeasures.key


                console.log("ADDDING ORDER MEASUREMENTS ")
                console.log(orderMeasures)


                console.log("THIS CUST MEASUREMENTS ")
                console.log(thisCust.conciergeMeasurements)

                if (orderMeasures == null && thisCust.conciergeMeasurements  !== null){
                    orderMeasures = thisCust.conciergeMeasurements
                }

                let newOrder = new Order(orderNo, null, thisCust.name, thisCust.email1, thisCust.phone1, deliveryAddress, props.totalAmount, currencySymbol, 0, props.shippingAmount, null, orderMeasures, null, null, "PENDING", thisCust, null, null, new Date(), null, null, false, null, true, null, null)

                let prods = []


                newOrder.add((succ, errMsg) => {

                    if (succ) {

                        // Add Products to this Order


                        props.products.map( obj => {
                            let np = Product.copyFrom(obj)
                            np.order = newOrder
                            np.customer = thisCust
                            np.productId = orderNo + '-P' + randomAlphabets(3)
                            np.add( (succ, errMsg) => {
                                if (succ){
                                    prods.push(np)
                                    console.log('Product Added')
                                    if (prods.length === props.products.length) {

                                        // All Products uploaded

                                        // props.orderAdded(newOrder, prods)

                                        cogoToast.success("Order Created. Added to your account.")

                                        callback({ success: true, message: 'Order is created. Address saved. ', order: newOrder, products: prods })

                                        // newOrder.products = prods
                                        // newOrder.update((succ, errMsg) => {
                                        //     if (succ){

                                        //     }else{
                                        //         console.log('ERROR WHILE ADDING PRODUCT - ' + np.productId)
                                        //         console.log(errMsg)
                                        //     }
                                        // })

                                    }
                                }else{
                                    console.log(errMsg)
                                }
                            })
                            return null
                        }) 


                        return



                    }else{
                        cogoToast.warn("Could not try to create order. Check internet connection and valid entries.")
                        callback({ success: false, message: 'Could not create order. ' + errMsg })
                        return
                    }

                })

    }

    return (

        <div className='is-two-fifths layerGreyBG pad20 fadeIn'>
            <br />
            <Heading className=' has-text-grey' size={6}>  {props.title ?? 'Deliver To'}  </Heading>

            <Formik
                initialValues={billingAddressValues}


                onSubmit={(data, { setSubmitting, resetForm }) => {

                    setSubmitting(true)
                    console.log('Form Submitted : ', data)


                    saveOrMakeOrder(data, (response) => {
                        setSubmitting(false)
                        // resetForm(true)
                        if (response.success === true) {

                            props.orderAdded(response.order, response.products)

                            // props.success(data)
                        } else {

                            props.error({ message: response.message })


                        }
                    })

                }}
            >
                {({ values, isSubmitting, errors, touched, isValidating }) => (

                    <Form >
                        <div className='field'>
                            {errors.name && touched.name && <div className="help type55 is-pulled-right is-danger">{errors.name}</div>}
                            <label className="label type60">Name</label>
                            <Field name='name' type='input' className='input has-text-weight-medium type65' validate={validateName} />
                        </div>
                        <div className='field'>
                            {errors.address && touched.address && <div className="help type55 is-pulled-right is-danger">{errors.address}</div>}
                            <label className="label type60">Address</label>
                            <Field name='address' type='input' className='input has-text-weight-medium type65' validate={validateAddress} />
                        </div>
                        <div className='field'>
                            {errors.state && touched.state && <div className="help type55 is-pulled-right is-danger">{errors.state}</div>}
                            <label className="label type60 marginTop10">City, State</label>
                            <Field name='state' type='input' className='input has-text-weight-medium type65' validate={validateCityState} />
                        </div>
                        <div className='field'>
                            {errors.country && touched.country && <div className="help type55 is-pulled-right is-danger">{errors.country}</div>}
                            <label className="label type60 marginTop10">Country</label>
                            {/* <CountrySelect countryChanged={countryChanged} /> */}
                            <Field name='country' type='input' className='input has-text-weight-medium type65' validate={validateCountry} />
                        </div>

                        <div className='field'>
                            {errors.pincode && touched.pincode && <div className="help type55 is-pulled-right is-danger">{errors.pincode}</div>}
                            <label className="label type60 ">Postcode</label>
                            <Field name='pincode' type='input' className='input has-text-weight-medium type65' validate={validatePincode} />
                        </div>

                        <div className='field'>
                            {errors.phone && touched.phone && <div className="help type55 is-pulled-right is-danger">{errors.phone}</div>}
                            <label className="label type60 marginTop10">Phone</label>
                            <Field name='phone' type='input' className='input has-text-weight-medium type65' validate={validatePhone} />
                        </div>

                        {/* <div className='field'>
                            <label className="label type60 marginTop10">Note</label>
                            <Field name='note' type='input' className='input has-text-weight-medium type65' />
                        </div> */}

                        {/* <hr />
                        <div className='field'>
                            <label className="label type60 marginTop10">Card Details</label>
                            <Field name='card' type='input' className='input has-text-weight-medium type65' />
                        </div> */}

                        < br />
                        <div className='control is-expanded'>
                            <Button disabled={isSubmitting || isValidating } loading={isSubmitting} className='button is-black is-fullwidth marginTop10' type='submit'  > Confirm Order </Button>
                        </div>

                    </Form>

                )}

            </Formik>
        </div>


    )
}

export default BillingForm