import React, { Component } from 'react'
import sample1 from '../assets/sample1.jpeg'
import sample2Cut from '../assets/sample2Cut.jpg'
import banner1 from '../assets/banner1.jpg'
import banner2 from '../assets/banner2.jpg'
import banner3 from '../assets/banner3.jpg'
import banner4 from '../assets/banner4.jpg'
import banner5 from '../assets/banner5.jpg'
import bannerClient from '../assets/bannerClient.jpg'
import bannerClient2 from '../assets/bannerClient2.jpg'

import sixBtn3 from '../assets/sixBtn3.jpg'
import sixBtn4 from '../assets/sixBtn4.png'
import suitLength from '../assets/suitLength.jpeg'
import singalButton from '../assets/singalButton.png'
import twoButtons from '../assets/twoButtons.png'
import double4Buttons from '../assets/double4Buttons.png'
import double6Buttons from '../assets/double6Buttons.png'
import fullButtons from '../assets/fullButtons.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload, faCheck, faArrowRight, faArrowAltCircleRight, faArrowCircleDown, faForward, faPlus, faPlusCircle, faPlusSquare, faPhone, faPhoneAlt, faSms, faMailBulk, faEnvelope, faSurprise, faSmileBeam, faSmile, faRibbon, faRainbow, faHatCowboy, faAirFreshener, faBirthdayCake, faHatCowboySide } from '@fortawesome/free-solid-svg-icons'


import blackmodalSuit from '../assets/blackmodalSuit.jpg'
// import Logo from '../assets/tw-logo-140x30.svg'
// import Logo from '../assets/logoTailorwise.svg'
import Logo from '../assets/logoClient.png'
import LogoNav from '../assets/logoClientNav.png'
// import Logo from '../assets/logoClientMenu.png'


import LoadingAux from './LoadingAux'
import ColumnAux from './ColumnAux'
import ChooseBox from './ChooseBox'
import StyleChooserWizard from './StyleChooserWizard'
import MeasurementsWizard from './MeasurementsWizard'
import StyleBox from './StyleBox'
import StyleCard from './StyleCard'

import ModalFullPage from './ModalFullPage'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal, Tag
} from 'react-bulma-components/dist'

import { imageNamed, PRODUCT_TYPE, uuidv4 } from '../utilities/utils'
import StylesSection from './StylesSection'
import MeasuresSection from './MeasuresSection'
import OrderSection from './OrderSection'

import MyAccount from './MyAccount'
import Shop from './Shop'
import ModalAux from './ModalAux'

import SignIn from './SignIn'
import SignUp from './SignUp'

import PaymentWrapper from './PaymentWrapper'

import Service from './Service'
import { hasShop, clientName, clientPhone, clientEmail, clientEmailFromForOrder, clientWebsite, currencySymbol, currencyCode, clientLogoUrl, standardDeliveryTime } from '../utilities/clientInfo'
import { sendMail, Order, Customer } from '../utilities/classes'

import cogoToast from 'cogo-toast'
import { getCurrentAdmin, getCurrentCustomer, removeCurrentAdmin, removeCurrentCustomer, saveCurrentAdmin, saveCurrentCustomer, tryCustomerLogin } from '../utilities/Login'
import PaymentPage from './PaymentPage'
import PaypalPage from './PaypalPage'
import WebAdmin from './WebAdmin'
import PaypalPaymentPage from './PaypalPaymentPage'
import OrderModal from './OrderModal'


const isOpenWithoutSignIn = true


class Webshop extends Component {



    MAIN_TABS = {
        pickStyles: 'Pick Styles',
        addMeasuremnt: 'Add Measurements',
        checkout: 'Checkout'
    }

    HOME_SCREEN = {
        welcome: 'Welcome',
        signUp: 'signUp',
        signIn: 'signIn'
    }

    toggleIsStarted = (override) => {

        if (isOpenWithoutSignIn) {
            if (getCurrentCustomer() !== null || getCurrentAdmin() !== null) {
                if (this._mounted) {
                    let ns = { ...this.state }
                    ns.showMyAccount = false
                    ns.isWebAdmin = false
                    // ns.showShop = true
                    // ns.menuActive = false
                    // ns.mainTab = this.MAIN_TABS.pickStyles
                    
                    ns.currentHomeScreen = this.HOME_SCREEN.welcome
                    if (override) {
                        ns.isStarted = override
                    }
                    ns.customer = null
                    removeCurrentAdmin()
                    removeCurrentCustomer()
                    this.setState(ns)
                }
            }

        } else {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.isStarted = !ns.isStarted
                ns.showMyAccount = false
                ns.isWebAdmin = false
                ns.showShop = hasShop && true
                ns.menuActive = false
                ns.mainTab = this.MAIN_TABS.pickStyles
                ns.currentHomeScreen = this.HOME_SCREEN.welcome
                if (override) {
                    ns.isStarted = override
                }
                this.setState(ns)
            }
        }
    }


    directToConcierge = () => {
        // let path = window.location.pathname
        // console.log('WINDOW PATH NAME = ', path)

        // let lastPop = window.location.pathname.split("/").pop()
        // console.log('LAST POP= ',lastPop)

        // let href = window.location.href
        // console.log('WINDOW HREF = ', href)

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        console.log('urlParams = ', urlParams)

        const directTo = urlParams.get('directTo')
        console.log('directTo = ', directTo)

        if (directTo === 'concierge') {
            return true
        }

        return false
    }


    state = {
        isStarted: isOpenWithoutSignIn,
        isWebAdmin: false,
        mainTab: this.MAIN_TABS.pickStyles,
        isPickingDone: false,
        isMeasureDone: false,
        isOrderDone: false,
        currentTitle: 'Concierge´Bespoke',
        currentWizard: null,
        showMeasureWizard: false,
        editingMeasure: null,
        products: [],
        measures: [],
        order: null,
        showMyAccount: false,
        showShop: (hasShop === true) && !this.directToConcierge(),
        menuActive: false,
        homeScreen: this.HOME_SCREEN.welcome,
        showContactModal: false,
        isOpenedInIFrame: false,
        showPaymentModal: false,
        showSuccessModal: false,
        finalPrice: 0,
        customer: null,
        signInModal: 'SignIn',
        showSignInModal: false,
        showOrderInModal: null,
        recentPaymentUpdated: false
    }

    _mounted = false
    _refreshOrders = false

    // styleName, description, front, tone, buttonDesign, collar, back, pantStyle

    existingStyles = []
    // existingStyles = [
    //     {
    //         productType: PRODUCT_TYPE.suit,
    //         styleName: 'Style A',
    //         frontImage: 'double6Buttons',
    //         fabricImage: 'fab_blue1',
    //         description: 'Its my style man. Give it to me',
    //         type: 'Created',
    //         style: {
    //             front: 'sixButton',
    //             tone: 'blues',
    //             fabric: 'fab_blue1',
    //             buttonDesign: 'buttonClassic',
    //             collar: 'collarSlim',
    //             back: 'sideCut',
    //             pantStyle: 'plain'
    //         }
    //     }
    // ]

    isIframe = () => {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    componentDidMount = () => {
        this._mounted = true

        const isIframe = this.isIframe()
        console.log('IS OPENED IN IFRAME = ', isIframe)




        if (isIframe) {
            // alert(' This is opened in iFrame ')
            let ns = { ...this.state }
            ns.isOpenedInIFrame = isIframe
            this.setState(ns)
        }


    }


    saveParseObject = () => {

        Service.saveCustomer('Seventh Customer')


    }


    componentWillMount = () => {
        this._mounted = false
    }

    // startJourney = () => {
    //     document.querySelector('.homeMainCol').classList.add('fade04')

    //     setTimeout(() => {
    //         this.updateToMultiComp()
    //     }, 400);
    // }



    backToHome = () => {
        // document.querySelector('.mainMultiComp').classList.add('fade04')

        // setTimeout(() => {
        this.updateToHome()
        // }, 400);
    }

    // updateToMultiComp = () => {
    //     if (this._mounted) {
    //         let newState = { ...this.state }
    //         newState.isStarted = true
    //         this.setState(newState)
    //     }
    // }

    updateToHome = () => {
        if (this._mounted) {
            let newState = { ...this.state }
            newState.isStarted = false
            this.setState(newState)
        }
    }

    multipartState = {
        page: 0,
        parts: [
            { title: 'Choose a wear', color: 'lightGrey', key: '563576537' },
            { title: 'Pick your style', color: 'grey', key: '5376563573' },
            { title: 'Add Measurements', color: 'white', key: '36753635637' },
        ]
    }

    sectionStyle = {
        backgroundImage: `url(${banner4})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
    };





    doneLetsMeasure = (styles) => {
        if (styles.length > 0) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.products = styles
                ns.isPickingDone = true
                this.setState(ns)

                setTimeout(() => {
                    this.changeMainTabHandler(this.MAIN_TABS.addMeasuremnt)
                }, 300);
            }
        }
    }

    doneLetsOrder = (measures) => {
        if (measures.length > 0) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.measures = measures
                ns.isMeasureDone = true
                this.setState(ns)

                setTimeout(() => {
                    this.changeMainTabHandler(this.MAIN_TABS.checkout)
                }, 300);
            }
        }
    }


    welcomeComp = () => {
        return (
            <Columns.Column size={7} className='has-background-white pad30 fadeIn '>
                <Button className='  type70 is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.signIn) }} > or Sign In  </Button>

                <img src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="180" height="60" onClick={this.logoPressed} />
                <br />
                <Heading size={3} className='is-size-4-mobile has-text-black has-text-semibold padTop20' >
                    <p className='margTop2'> Pick your style.</p>
                    <p className='margTop2'> Add measurements.</p>
                    <p className='margTop2'> Get Bespoke delivered.</p>
                </Heading>
                <br />
                <Button className=' is-radiusless is-pulled-right is-black' onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.signUp) }}> Get Started
            <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i>
                </Button>
            </Columns.Column>
        )
    }


    errorOccured = (er) => {
        // alert(er.message)
        console.log(er.message)
    }

    signUpCompleteInModal = (payload) => {
        console.log('Sign UP Happened With = ', payload)

        if (this._mounted) {
            let ns = { ...this.state }
            ns.showSignInModal = false
            ns.signInModal = 'SignIn'
            let cust = getCurrentCustomer()
            ns.customer = cust

            if (payload.isWebAdmin) {
                ns.isWebAdmin = payload.isWebAdmin
            }
            this.setState(ns)
        }

        if (this.state.measures.length > 0) {
            let cust = getCurrentCustomer()
            this.saveMeasurementsInCustomer(cust, this.state.measures)
        }
    }

    signUpComplete = (payload) => {
        console.log('Sign UP Happened With = ', payload)
        this.toggleIsStarted()

        if (this._mounted) {
            let ns = { ...this.state }
            let cust = getCurrentCustomer()
            ns.customer = cust
            if (payload.isWebAdmin) {
                ns.isWebAdmin = payload.isWebAdmin
            }
            this.setState(ns)
        }

        // if (this.state.measures.length > 0) {
        //     let cust = getCurrentCustomer()
        //     this.saveMeasurementsInCustomer(cust, this.state.measures)
        // }
    }

    signUpComp = (isInModal) => {

        if (isInModal === true) {
            return (
                <Columns>
                    <Columns.Column size={isInModal ? 12 : 12} className='has-background-white pad30' >
                        <Button className='is-small  is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { (isInModal === true) ? this.showSignInInModal() : this.changeWelcomeScreen(this.HOME_SCREEN.signIn) }} > or Sign In </Button>
                        <img src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} />
                        <br />
                        {isInModal ? <div> <p size={6} className=' type74  marginTop20 has-text-grey-light'> Create Account. To track your orders and save measurements. </p>  </div> : null}
                        <div >
                            <SignUp isSignedUp={isInModal ? this.signUpCompleteInModal : this.signUpComplete} errorOccured={this.errorOccured} />

                        </div>
                    </Columns.Column>
                </Columns>
            )

        } else {
            return (
                <Columns.Column size={4} className='has-background-white pad30' >
                    <Button className='is-small  is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { (isInModal === true) ? this.showSignInInModal() : this.changeWelcomeScreen(this.HOME_SCREEN.signIn) }} > or Sign In </Button>
                    <img src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} />
                    <br />
                    {isInModal ? <div> <p size={6} className=' type74  marginTop20 has-text-grey-light'> Create Account. To track your orders and save measurements. </p>  </div> : null}
                    <div >
                        <SignUp isSignedUp={isInModal ? this.signUpCompleteInModal : this.signUpComplete} errorOccured={this.errorOccured} />

                    </div>
                </Columns.Column>
            )

        }



    }


    signInCompleteInModal = (payload) => {
        if (payload === 'forgotPassword') {
            console.log('HAS FOR FORGOTTEN PASSWORD - Take to that screen')
            return
        }

        cogoToast.success('Welcome Back')

        if (this._mounted) {
            let ns = { ...this.state }
            ns.showSignInModal = false
            ns.signInModal = 'SignUp'
            let cust = getCurrentCustomer()
            ns.customer = cust

            if (payload.isWebAdmin) {
                ns.isWebAdmin = payload.isWebAdmin
            }
            this.setState(ns)
        }


        if (this.state.measures.length > 0) {
            let cust = getCurrentCustomer()
            this.saveMeasurementsInCustomer(cust, this.state.measures)
        }
    }


    signInComplete = (payload) => {
        if (payload === 'forgotPassword') {
            console.log('HAS FOR FORGOTTEN PASSWORD - Take to that screen')
            return
        }
        this.toggleIsStarted()

        cogoToast.success('Welcome Back')


        if (this._mounted) {
            let ns = { ...this.state }
            let cust = getCurrentCustomer()
            ns.customer = cust
            if (payload.isWebAdmin) {
                ns.isWebAdmin = payload.isWebAdmin
            }
            this.setState(ns)
        }


        // if (this.state.measures.length > 0) {
        //     let cust = getCurrentCustomer()
        //     this.saveMeasurementsInCustomer(cust, this.state.measures)
        // }
    }


    showSignUpInModal = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showSignInModal = true
            ns.signInModal = 'SignUp'
            this.setState(ns)
        }

    }

    signInModalClosed = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showSignInModal = false
            this.setState(ns)
        }
    }

    showSignInInModal = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showSignInModal = true
            ns.signInModal = 'SignIn'
            this.setState(ns)
        }
    }

    signInComp = (isInModal) => {

        if (isInModal === true) {
            return (
                <Columns>
                    <Columns.Column size={isInModal ? 12 : 12} className='has-background-white pad30  '>
                        <Button className='is-small is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { (isInModal === true) ? this.showSignUpInModal() : this.changeWelcomeScreen(this.HOME_SCREEN.signUp) }} > or Create Account </Button>

                        <img src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} />
                        <br />
                        {isInModal ? <div> <p size={6} className=' type74 marginTop20 has-text-grey-light'> Sign In. To track your orders and save measurements. </p>  </div> : null}
                        <div >
                            <SignIn isSignedIn={isInModal ? this.signInCompleteInModal : this.signInComplete} errorOccured={this.errorOccured} hasForgotPassword={() => { this.signInComplete('forgotPassword') }} />
                        </div>
                    </Columns.Column>
                </Columns>

            )

        } else {
            return (
                <Columns.Column size={4} className='has-background-white pad30  '>
                    <Button className='is-small is-text is-pulled-right is-radiusless has-text-grey-light no-decoration' onClick={() => { (isInModal === true) ? this.showSignUpInModal() : this.changeWelcomeScreen(this.HOME_SCREEN.signUp) }} > or Create Account </Button>

                    <img src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" onClick={() => { this.changeWelcomeScreen(this.HOME_SCREEN.welcome) }} />
                    <br />
                    {isInModal ? <div> <p size={6} className=' type74 marginTop20 has-text-grey-light'> Sign In. To track your orders and save measurements. </p>  </div> : null}
                    <div >
                        <SignIn isSignedIn={isInModal ? this.signInCompleteInModal : this.signInComplete} errorOccured={this.errorOccured} hasForgotPassword={() => { this.signInComplete('forgotPassword') }} />
                    </div>
                </Columns.Column>
            )

        }


    }


    currentHomeScreen = () => {
        switch (this.state.currentHomeScreen) {
            case this.HOME_SCREEN.welcome: return this.welcomeComp();
            case this.HOME_SCREEN.signIn: return this.signInComp();
            case this.HOME_SCREEN.signUp: return this.signUpComp();
            default: return this.welcomeComp();
        }
    }


    changeWelcomeScreen = (screen) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.currentHomeScreen = screen
            this.setState(ns)
        }
    }


    landingPage = () => {
        return (
            <Section className='' style={this.sectionStyle}>
                <Hero className=' is-fullheight  '>
                    <Hero.Body className=' '>
                        <Container className=''>
                            <Columns className='is-centered  '>
                                {this.currentHomeScreen()}
                            </Columns>
                        </Container>
                    </Hero.Body>
                </Hero>
            </Section>
        )
    }



    home = (
        <>
            <Columns className=''>

                <Columns.Column />
                <Columns.Column size={7} className='marginTop40'>
                    <div className='homeMainCol '>

                        <br />
                        <Button className=' is-radiusless is-fullwidth is-medium is-black' onClick={this.startJourney}> Get Started </Button>
                        <br />
                        <br />

                        <div className=''>
                            <figure className="image is-800x600 ">
                                <img src={sample2Cut} alt='Tailorwise studio' />
                            </figure>
                        </div>


                    </div>
                </Columns.Column>
                <Columns.Column />
            </Columns>
        </>
    )






    cancelPressedInWizard = () => {
        // clear any imaages of keys
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMeasureWizard = false
            ns.editingMeasure = false
            this.setState(ns)
        }
    }


    getMeasurementPage = () => {
        // if (this.state.showWizards) {
        // CHECK THE SELECTED WIZARD HERE
        // return <Box className='is-radiusless is-shadowless layerGreyBG  minHeight60vh '>
        return <Box className='is-radiusless is-shadowless  minHeight60vh '>

            <MeasurementsWizard productType={PRODUCT_TYPE.suit} editingMeasure={this.state.editingMeasure} cancelPressed={this.cancelPressedInWizard} isDone={this.styleChooserComplete} />
        </Box>
        // }
    }


    toggleContactModal = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showContactModal = !ns.showContactModal
            ns.menuActive = false
            this.setState(ns)
        }
    }


    sendOrderCreatedMail = (ord, products, measures, paymentInfo, amount, currency) => {

        let header = {
            "logoUrl": clientLogoUrl,
            "orderNo": ord.orderNo,
            "clientName": ord.customerName,
            "shippingDuration": standardDeliveryTime
        }

        let productsInfo = []
        products.forEach((prod) => {
            let prodInfo = {
                "imageUrl": prod.imageUrl ?? "",
                "type": prod.type,
                "name": prod.name,
                "description": ((prod.isBespoke ? 'BESPOKE · ' : 'MADE TO ORDER · ') + prod.description)
            }
            productsInfo.push(prodInfo)
        })




        let measureAndDelivery = {
            "measuresDescription": "",
            "clientName": ord.customerName,
            "deliveryAddress": ord.deliveryAddress
        }

        let measurements = measures
        let measureDescription = ""

        console.log("=====MEASURESMENTS ARE=====")
        console.log(measurements)


        let cust = getCurrentCustomer()
        let existingMeasure = cust.conciergeMeasurements ?? null

        if (measurements === null || !measurements.length) {
            if (existingMeasure !== null) {
                measurements = [{ measures: existingMeasure, description: "", key: "sjbskjjbjbskjiui34" }]
                measureDescription = "Measurement Skipped - Last saved measurements attached : "
            }
        }




        if (measurements.length) {
            let thisMeasure = measurements[0]
            let allMeasures = thisMeasure.measures

            console.log("=====ALL MEASURES=====")
            console.log(allMeasures)

            let keysArr = Object.keys(allMeasures)
            console.log("=====KEYS ARR=====")
            console.log(keysArr)

            let subDesc = ""
            Object.keys(allMeasures).map((key) => {
                if (key === 'AGE' || key === 'Age' || key === 'age') {
                    return null
                }
                if (key === 'WEIGHT' || key === 'Weight' || key === 'weight') {
                    return null
                }
                let str = `\n • ${key} = ${allMeasures[key].value} ${allMeasures[key].unit} `
                subDesc += str
                return null
            });

            measureDescription = `${measureDescription} \n ${subDesc}`

            measureAndDelivery.measuresDescription += measureDescription
        } else {
            measureAndDelivery.measuresDescription = "Measurement Skipped"
        }

        let paymentSummary = {
            "totalPayment": amount,
            "currency": currency,
            "paymentInfo": paymentInfo
        }

        let footer = {
            "website": clientWebsite,
        }

        let fromEmail = clientEmailFromForOrder
        let toEmail = ord.customerEmail
        let subject = `Order Created - ${ord.orderNo}`
        let mailTitle = "Order Created"



        sendMail(header, productsInfo, measureAndDelivery, paymentSummary, footer, fromEmail, toEmail, subject, mailTitle)


    }


    paymentDone = (paymentId, status, amountInHundred, currency, gateway) => {
        //

        let amount = amountInHundred

        let idType = "PaypalGeneratedId"

        if (gateway === "STRIPE") {
            amount = amountInHundred / 100
            idType = "PaymentId"
        }

        let ns = { ...this.state }
        let thisOrder = { ...ns.order }


        let ord = Order.copyFrom(thisOrder)

        ord.isPaid = true

        let paymentInfo = `${gateway}. ${idType} ${paymentId}. Amount ${amount} ${currency}. Status ${status}.`
        ord.paymentInfo = paymentInfo

        // Update Order

        ord.update((succ, errMsg) => {
            if (succ) {

                ns.order = ord
                ns.recentPaymentUpdated = true
                this.setState(ns)


                this.toggleSuccessOrderModal(true)
            } else {
                console.log(errMsg)
            }
        })



    }




    toggleSuccessOrderModal = (toMyAccount) => {

        console.log('SHOWING SUCCESS')

        this._refreshOrders = true
        setTimeout(() => {
            this._refreshOrders = false

        }, 800);

        if (this._mounted) {
            let ns = { ...this.state }
            ns.showPaymentModal = false
            ns.showSuccessModal = !ns.showSuccessModal
            if (toMyAccount) {
                ns.showMyAccount = true
                ns.recentPaymentUpdated = false
                ns.showShop = false
                ns.products = []
                ns.finalPrice =
                    ns.order = null
                ns.isPickingDone = false
                ns.isMeasureDone = false
            }
            this.setState(ns)
        }
    }

    stylesChanged = (styles) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.products = styles
            if (styles && styles.length > 0) {
                // ns.isMeasureDone = true
            } else {
                ns.isPickingDone = false
            }

            console.log('**UPDATING STYLES CHANGED')
            console.log(styles)

            this.setState(ns)
        }
    }

    productAdded = (prod) => {

        let thisProd = { ...prod }

        thisProd.key = uuidv4()
        console.log("WITH UNIQUEID =", thisProd.key)

        if (this._mounted) {
            let ns = { ...this.state }
            let existingProds = [...ns.products]
            existingProds.push(thisProd)
            ns.products = existingProds
            this.setState(ns)
        }
    }


    saveMeasurementsInCustomer = (cust, measures) => {

        let existingMeasures = cust.conciergeMeasurements ?? null
        let existingCopy = {}
        if (existingMeasures != null) {
            existingCopy = { ...existingMeasures }
        }

        console.log("PRE EXISTING COPY =")
        console.log(existingCopy)

        // console.log("Measures are")
        // console.log(measures)

        if (measures != null) {
            if (measures.length > 0) {

                let measureObj = measures[0]
                let currentMeasures = { ...measureObj.measures }

                const keys = Object.keys(currentMeasures);

                keys.forEach((key, index) => {
                    existingCopy[key] = currentMeasures[key]
                })

                console.log("EXISTING COPY =")
                console.log(existingCopy)

                cust.conciergeMeasurements = existingCopy

                let thisCust = Customer.copyFrom(cust)
                thisCust.conciergeMeasurements = existingCopy
                // thisCust.conciergeMeasuresUpdatedOn = new Date()

                thisCust.update((succ, errMsg) => {
                    if (succ === true) {
                        saveCurrentCustomer(thisCust)
                        cust = thisCust
                        cogoToast.success("Measurements Saved")
                        // callback({ success: true, message: 'Address Saved succesfully. ' })
                    } else {
                        cogoToast.warn("Could not upload measurements. Check valid entries, internet connection and try again.")
                        // callback({ success: false, message: 'Could not update address. ' + errMsg })
                    }

                })

            }
        }
    }


    measuresChanged = (measures) => {

        // alert("MEASURES CHANGED")

        // 

        if (this._mounted) {
            let ns = { ...this.state }
            ns.measures = measures
            if (measures && measures.length > 0) {
                ns.isMeasureDone = true
            } else {
                ns.isMeasureDone = false
            }
            this.setState(ns)
        }

        // CHECK CURRENT USER MEASUREMENTS 

        let cust = getCurrentCustomer()

        if (cust === null) {
            // Ask for SignIn 

            this.signInViaModal()

        } else {
            this.saveMeasurementsInCustomer(cust, measures)
        }




    }

    pickSection = () => {
        return (
            <ColumnAux noPaddingSides={true} isMiddle={true} size={8}>
                {/* <Box className=' has-background-white is-vcentered marginHorizontal10'>
                    <Heading size={7}> Your Style Has Been Saved <Button className=' topMarginMinus8 is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
                </Box> */}
                <StylesSection customer={this.state.customer} products={this.state.products} stylesChanged={this.stylesChanged} doneLetsMeasure={this.doneLetsMeasure} />
                {/* {this.getPickPage()} */}
            </ColumnAux>
        )

    }

    measurementSection = (prods) => {

        console.log('SENDING PRODS IN MEASURES')
        console.log(prods)

        let prArr = []
        prods.forEach(st => {
            prArr.push(st.productType)
        })

        return (
            <ColumnAux noPaddingSides={true} isMiddle={true} size={8}>
                {/* <Box className=' has-background-white is-vcentered marginHorizontal10'>
                <Heading size={7}> Your Style Has Been Saved <Button className=' topMarginMinus8 is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            </Box> */}
                <MeasuresSection customer={this.state.customer} productsArray={prArr} styles={prods} measures={this.state.measures} measuresChanged={this.measuresChanged} doneLetsOrder={this.doneLetsOrder} />
                {/* {this.getMeasurementPage()} */}
            </ColumnAux>
        )
    }

    changeMainTabHandler = (tabName) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.mainTab = tabName
            if (tabName === this.MAIN_TABS.addMeasuremnt) {
                // ns.isPickingDone = true
            }
            // if (tabName === this.MAIN_TABS.pickStyles) {
            //     ns.currentTitle = 'Pick Your Styles'
            // }
            // if (tabName === this.MAIN_TABS.addMeasuremnt) {
            //     ns.currentTitle = 'Add Measurement'
            // }
            // if (tabName === this.MAIN_TABS.checkout) {
            //     ns.currentTitle = 'Get It Delivered'
            // }

            this.setState(ns)
        }
    }



    mainTabs = () => {
        return (<div className=' fadeIn '>

            <Heading size={4} className='is-size-4-mobile  has-text-centered  noLastMargin  ' >
                {this.state.currentTitle}
            </Heading>

            <Columns className='is-marginless is-mobile is-paddingless has-text-centered pad0'>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {this.state.isPickingDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>}
                </Columns.Column>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {this.state.isMeasureDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>}
                </Columns.Column>
                <Columns.Column className='is-marginless is-paddingless  pad0'>
                    {this.state.isOrderDone ? <p className="type60 has-text-weight-light"> <FontAwesomeIcon className='' icon={faCheck} /></p> : <p className='marginTop19'></p>}
                </Columns.Column>
            </Columns>


            <Tabs fullwidth align='centered' className='is-mobile is-small  boldTabs has-text-black  '>
                <Tabs.Tab active={this.state.mainTab === this.MAIN_TABS.pickStyles} onClick={() => this.changeMainTabHandler(this.MAIN_TABS.pickStyles)}>
                    MY STYLES
                </Tabs.Tab>
                <Tabs.Tab active={this.state.mainTab === this.MAIN_TABS.addMeasuremnt} onClick={() => this.changeMainTabHandler(this.MAIN_TABS.addMeasuremnt)}>
                    MEASURE
                </Tabs.Tab>
                <Tabs.Tab active={this.state.mainTab === this.MAIN_TABS.checkout} onClick={() => this.changeMainTabHandler(this.MAIN_TABS.checkout)}>
                    ORDER
                </Tabs.Tab>
            </Tabs>

        </div>)
    }

    toggleMenu = () => {
        const newState = { ...this.state }
        newState.menuActive = !newState.menuActive
        this.setState(newState)
    }

    categoryPageHeader = (childElement) => {
        return (
            <div>
                <Columns className='is-centered  ' >
                    <Columns.Column size={8} className=' is-marginless   bottomPadding0'>
                        {this.mainTabs()}
                    </Columns.Column>
                </Columns>

                <Container className=''>
                    {/* <Section> */}
                    {childElement}
                    {/* </Section> */}
                </Container>
            </div>
        )
    }


    logoPressed = () => {
        console.log('Home is Pressed')

        window.location.href = clientWebsite


        // this.backToHome()
    }




    showMyAccountHandler = (show) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMyAccount = show
            ns.recentPaymentUpdated = false
            ns.showOrderInModal = null
            ns.showShop = false
            ns.menuActive = false
            this.setState(ns)
        }
    }


    showShopHandler = (show) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showShop = show
            ns.showMyAccount = false
            ns.recentPaymentUpdated = false
            ns.menuActive = false
            this.setState(ns)
        }
    }

    showPaymentModalHandler = (show) => {

        if (this.state.order != null) {

            console.log('SHOW PAYMENT MODAL HANDLER WAS CALLED with = ', show)

            if (this._mounted) {
                let ns = { ...this.state }
                ns.showPaymentModal = show
                ns.showContactModal = false
                this.setState(ns)
            }
        } else {
            cogoToast.warn('Could not find Order Details to initiate payment. Navigate back and try again.')
        }

    }

    updateFinalPrice = (fp) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.finalPrice = fp
            this.setState(ns)
        }
    }


    orderAdded_old = (order, products) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.order = order
            ns.order.products = products
            ns.products = products
            this.setState(ns)

            setTimeout(() => {
                this.showPaymentModalHandler(true)
            }, 500);

        }
    }

    orderAdded = (order, products) => {

        this.sendOrderCreatedMail(order, products, [...this.state.measures], "PENDING", this.state.finalPrice, currencyCode)

        if (this._mounted) {
            let ns = { ...this.state }

            // ns.order.products = products
            // ns.products = products

            // clear all
            ns.showMyAccount = true
            ns.showShop = false
            ns.products = []
            ns.finalPrice = 0
            ns.order = null
            ns.isPickingDone = false
            ns.isMeasureDone = false

            // create complete order and send to OrderModal for payment
            let newOrder = order
            newOrder.products = products
            ns.showOrderInModal = newOrder

            this.setState(ns)

            // setTimeout(() => {
            //     this.showPaymentModalHandler(true)
            // }, 500);

        }
    }



    changeTabToConcierge = (closeMyAccount) => {
        if (closeMyAccount) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.showMyAccount = false
                ns.showShop = false
                ns.mainTab = this.MAIN_TABS.pickStyles
                this.setState(ns)
            }
        } else {
            this.changeMainTabHandler(this.MAIN_TABS.pickStyles)
        }
    }

    changeTabToMeasures = (closeMyAccount) => {

        if (closeMyAccount) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.showMyAccount = false
                ns.showShop = false
                ns.mainTab = this.MAIN_TABS.addMeasuremnt
                this.setState(ns)
            }
        } else {
            this.changeMainTabHandler(this.MAIN_TABS.addMeasuremnt)
        }

    }

    signInViaModal = () => {

        // SHOW SIGN IN 

        // alert('SHOWING SIGN IN')

        this.showSignInInModal()

    }

    customerUpdated = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showMyAccount = false
            ns.showShop = false
            ns.mainTab = this.MAIN_TABS.pickStyles
            this.setState(ns)
        }
    }

    billingAddressPage = () => {
        // alert('Order Section in Webshop')
        return (
            <OrderSection signInViaModal={this.signInViaModal} customer={this.state.customer} customerUpdated={this.customerUpdated} styles={this.state.products} measures={this.state.measures} paymentStart={this.showPaymentModalHandler} order={this.state.order} orderAdded={this.orderAdded} orderMade={this.showMyAccountHandler} updateFinalPrice={this.updateFinalPrice} showConcierge={this.changeTabToConcierge} showMeasures={this.changeTabToMeasures} showMyAccount={this.showMyAccountHandler} />
        )
    }


    measurementsPage = () => {
        return (

            this.measurementSection(this.state.products)

        )
    }

    getPickStylePage = () => {
        return this.categoryPageHeader(this.pickSection())
    }

    getAddMeasurementsPage = () => {
        let page = this.measurementsPage()

        return this.categoryPageHeader(page)
    }

    getCheckoutPage = () => {
        return this.categoryPageHeader(this.billingAddressPage())

    }


    getCurrentPage = () => {

        if (this.state.isWebAdmin === true) {
            return <WebAdmin />
        }


        let currentPage = this.landingPage()
        if (this.state.isStarted) {

            if (this.state.showMyAccount) {
                return this.myAccountPage()
            }

            if (this.state.showShop === true) {
                return this.shopPage()
            }


            if (this.state.mainTab === this.MAIN_TABS.pickStyles) {
                currentPage = this.getPickStylePage()
            }
            if (this.state.mainTab === this.MAIN_TABS.addMeasuremnt) {
                currentPage = this.getAddMeasurementsPage()
            }
            if (this.state.mainTab === this.MAIN_TABS.checkout) {
                currentPage = this.getCheckoutPage()
            }
        }

        return currentPage

        // return this.getAddMeasurementsPage()

    }


    adminNavBar = () => {

        if (this.state.isStarted === false) {
            return null
        }

        return (
            <Navbar active={this.state.menuActive} transparent className=' ' >

                <Navbar.Brand >
                    <Navbar.Item className='logo ' >
                        {this.state.isOpenedInIFrame ? null : <img src={LogoNav} alt="Tailorwise: Streamlined Bespoke Tailoring" width="140" height="30" onClick={this.logoPressed} />}
                    </Navbar.Item>
                    {/* <Navbar.Item className=' ' >
                        <p className='is-size-7 is-italic has-text-grey is-centered'> Demo Contents </p>
                    </Navbar.Item> */}
                    <Navbar.Burger onClick={this.toggleMenu} />

                </Navbar.Brand>
                <Navbar.Menu >

                    <Navbar.Container position="end">
                        {
                            (getCurrentAdmin() != null) ?
                                <Navbar.Item className='is-size-7 is-uppercase has-text-grey  has-text-weight-semibold ' onClick={() => { this.toggleIsStarted(false) }} >   Logout </Navbar.Item>
                                :
                                <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold ' onClick={() => { this.showSignInInModal() }} > Sign In </Navbar.Item>
                        }

                    </Navbar.Container>
                </Navbar.Menu>
            </Navbar>
        )
    }



    iFrameNavTabs = () => {

        if (this.state.isStarted === false) {
            return null
        }

        return (
            <>
                <Tabs fullwidth type={'toggle'} align='centered' className='is-mobile isBlackToggle whiteBoldTabs'>

                    {(hasShop === true) ?
                        <Tabs.Tab active={this.state.showShop} onClick={() => this.showShopHandler(true)}>
                            Shop
                        </Tabs.Tab>
                        : null}


                    <Tabs.Tab active={this.state.showMyAccount === false && this.state.showShop === false} onClick={() => this.showMyAccountHandler(false)}>
                        Concierge
                    &nbsp; {(this.state.products.length > 0) ? <Tag className="is-small marginleft" color="light" rounded> {this.state.products.length} </Tag> : null}
                    </Tabs.Tab>


                    {
                        getCurrentCustomer() != null ?
                            <>
                                <Tabs.Tab active={this.state.showMyAccount} onClick={() => this.showMyAccountHandler(true)}>
                                    My Account
                                </Tabs.Tab>
                                {/* <Tabs.Tab active={false} onClick={() => this.toggleIsStarted(false)}>
                                    Logout
                                </Tabs.Tab> */}
                            </>

                            :

                            <Tabs.Tab active={false} onClick={() => this.showSignInInModal()}>
                                Sign In
                    </Tabs.Tab>
                    }
                </Tabs>
                <br />
            </>

        )

    }



    navBar = () => {

        if (this.state.isStarted === false) {
            return null
        }

        return (
            <Navbar active={this.state.menuActive} transparent className=' ' >

                <Navbar.Brand >
                    <Navbar.Item className='logo ' >
                        {this.state.isOpenedInIFrame ? null : <img src={LogoNav} alt="Tailorwise: Streamlined Bespoke Tailoring" width="140" height="30" onClick={this.logoPressed} />}
                    </Navbar.Item>
                    {/* <Navbar.Item className=' ' >
                        <p className='is-size-7 is-italic has-text-grey is-centered'> Demo Contents </p>
                    </Navbar.Item> */}
                    <Navbar.Burger onClick={this.toggleMenu} />

                </Navbar.Brand>
                <Navbar.Menu >

                    <Navbar.Container position="end">

                        {(hasShop === true) ?
                            <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold' onClick={() => { this.showShopHandler(true) }}>
                                {/* <Button color='' onClick={this.toMyAccount} className={'is-radiusless is-small'} >Concierge</Button> */}
                                Shop
                            </Navbar.Item>
                        : null}


                        <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold' onClick={() => { this.showMyAccountHandler(false) }}>
                            {/* <Button color='' onClick={this.toMyAccount} className={'is-radiusless is-small'} >Concierge</Button> */}
                            Concierge
                             {(this.state.products.length > 0) ? <Tag className="is-small marginleft10" color="light" rounded> {this.state.products.length} </Tag> : null}
                        </Navbar.Item>

                        {
                            (getCurrentCustomer() != null) ?
                                <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold' onClick={() => { this.showMyAccountHandler(true) }}>
                                    {/* <Button color='' onClick={this.toMyAccount} className={'is-radiusless is-small'} >My Account</Button> */}
                                    My Account
                                </Navbar.Item>
                                :
                                null
                        }


                        {this.state.isOpenedInIFrame ? null :
                            <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold' onClick={this.toggleContactModal} >
                                {/* <Button color='black' loading={this.state.isLoggingOut} className={'is-radiusless is-small'} >Contact Store</Button> */}
                                                Contact
                                            </Navbar.Item>
                        }

                        {
                            (getCurrentCustomer() != null) ?
                                <Navbar.Item className='is-size-7 is-uppercase has-text-grey  has-text-weight-semibold ' onClick={() => { this.toggleIsStarted(false) }} >   Logout </Navbar.Item>
                                :
                                <Navbar.Item className='is-size-7 is-uppercase  has-text-weight-semibold ' onClick={() => { this.showSignInInModal() }} > Sign In </Navbar.Item>
                        }

                    </Navbar.Container>
                </Navbar.Menu>
            </Navbar>
        )
    }

    myAccountPage = () => {
        return <MyAccount refreshOrders={this._refreshOrders} isOpenedInIFrame={this.state.isOpenedInIFrame} showMyAccount={this.showMyAccountHandler} toggleContactModal={this.toggleContactModal} showConcierge={this.changeTabToConcierge} showMeasures={this.changeTabToMeasures} logoutPressed={() => { this.toggleIsStarted(false) }} recentPaymentUpdated={this.state.recentPaymentUpdated} />
    }


    modalOrderUpdated = (updatedOrder) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.recentPaymentUpdated = false
            ns.showOrderInModal = updatedOrder
            this.setState(ns)
        }
    }

    recentPaymentUpdated = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.recentPaymentUpdated = true
            this.setState(ns)
        }
    }

    orderModal = () => {
        return <OrderModal order={this.state.showOrderInModal} isNewOrder={true} closed={this.showMyAccountHandler} orderUpdated={this.modalOrderUpdated} recentPaymentUpdated={this.recentPaymentUpdated} />
    }

    showConcierge = () => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.showShop = false
            this.setState(ns)
        }
    }

    shopPage = () => {
        return <Shop isOpenedInIFrame={this.state.isOpenedInIFrame} showMyAccount={this.showMyAccountHandler} toggleContactModal={this.toggleContactModal} showConcierge={this.showConcierge} productAdded={this.productAdded} addedProducts={this.state.products} />
    }




    signInModal = () => {

        // console.log('ORDER IS ')
        // console.log(this.state.order)

        return (
            <Modal show={this.state.showSignInModal} closeOnBlur={false} closeOnEsc={false} showClose={true} onClose={() => this.signInModalClosed()} >
                <Modal.Content className='is-padingless'>
                    <ColumnAux isMiddle={true} size={8} >
                        {this.state.signInModal === 'SignIn' ? this.signInComp(true) : this.signUpComp(true)}
                    </ColumnAux>
                </Modal.Content>
            </Modal>
        )
    }



    paymentModal = () => {

        // console.log('ORDER IS ')
        // console.log(this.state.order)

        return (
            <Modal show={this.state.showPaymentModal} closeOnBlur={false} closeOnEsc={false} showClose={true} onClose={() => this.showPaymentModalHandler(false)} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>

                        <ColumnAux isMiddle={true} size={10} className=' is-centered has-text-centered'>
                            <img className='is-centered' src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" />
                            <br />
                            <br />
                            {/* <Heading size={6} className='is-size-4-mobile has-text-black has-text-semibold' >
                            <p className=' type70 has-text-weight-light has-text-grey'> Connect anytime!</p>
                            <br />
                            <p className=''> info@tailorwise.com</p>
                            <p className=''> +11 62535635364</p>
                        </Heading> */}

                            {/* <PaymentWrapper order={this.state.order} finalPrice={this.state.finalPrice} orderSuccess={this.toggleSuccessOrderModal}  paymentDone={this.paymentDone} /> */}

                            {/* <PaypalPage order={this.state.order} finalPrice={this.state.finalPrice}  paymentDone={this.paymentDone} /> */}
                            {/* <PaymentWrapper order={this.state.order} finalPrice={this.state.finalPrice} paymentDone={this.paymentDone} /> */}
                            <PaypalPaymentPage order={this.state.order} finalPrice={this.state.finalPrice}  paymentDone={this.paymentDone} />
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>
        )
    }

    orderSuccessModal = () => {
        return (
            <Modal show={this.state.showSuccessModal} closeOnBlur={true} showClose={true} onClose={this.toggleSuccessOrderModal} >
                <Modal.Content className=''>
                    <Box className='is-radiusless  fadeIn'>
                        <ColumnAux isMiddle={true} size={10} className='has-background-white is-centered  marginTop10'>
                            {/* <img className='is-centered' src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" /> */}
                            {/* <br /> */}
                            <div size={5} className=' avenir has-text-weight-semibold  has-text-success'> That Looks Good!  </div>
                            <p className='type60 has-text-weight-light has-text-grey '> We will soon confirm your order. And carry out this gist of tasks.</p>
                            <br />
                            <div className='.fadeup'>

                                <ColumnAux className='' isMiddle={false} size={11}>
                                    <Box className='pad0 fade3  marginTop10 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />
                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> We will analyse your details.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-1-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>
                                <ColumnAux isMiddle={false} size={11}>
                                    <Box className='pad0 fade6 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />

                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> Handpick & Handcraft.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-2-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>

                                <ColumnAux isMiddle={false} size={11}>
                                    <Box className='pad0 fade9 is-radiusless' >
                                        <Columns className='is-mobile'>
                                            <Columns.Column size={1} />

                                            <Columns.Column size={5}>
                                                <div className='is-size-7 has-text-weight-light  verticalLeftCenter'> Get Your Apparel Ready.</div>
                                            </Columns.Column>
                                            <Columns.Column>
                                                <Image className='is-clipped ' src={imageNamed('success-process-3-bw')} size='3by2' />
                                            </Columns.Column>
                                            <Columns.Column size={1} />

                                        </Columns>
                                    </Box>
                                </ColumnAux>

                                <p className='is-expanded marginTop30'>
                                    <Button className='  is-radiusless type74 is-black' onClick={() => { this.toggleSuccessOrderModal(true) }}  > VIEW ORDER
                            <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i>
                                    </Button>
                                    {/* <Button className=' marginLeft20  is-radiusless type60 is-text no-decoration' onClick={() => { this.toggleSuccessOrderModal() }} > CONTINUE ORDERING </Button> */}
                                </p>
                                <br />
                                <br />
                            </div>


                        </ColumnAux>
                    </Box>
                </Modal.Content>
            </Modal>
        )
    }

    contactModal = () => {
        return (
            <Modal show={this.state.showContactModal} closeOnBlur={true} showClose={true} onClose={this.toggleContactModal} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn'>

                        <ColumnAux isMiddle={true} size={6} className='has-background-white is-centered has-text-centered'>
                            <img className='is-centered' src={Logo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="120" height="30" />
                            <br />
                            <br />
                            <div className='is-size-4-mobile has-text-black has-text-semibold' >
                                <p className=' has-text-weight-light has-text-grey is-italic'> Connect anytime!</p>
                                <br />
                                <Heading size={6} className=''>
                                    <p>
                                        <a href={'mailto:' + clientEmail}>{clientEmail}</a>
                                    </p>
                                    <br />
                                    <p>
                                        <a className='marginTop20' href={'tel:' + clientPhone}>{clientPhone}</a>
                                    </p>

                                </Heading>
                            </div>
                            <hr />
                            <Button className=' is-radiusless  is-black'  >
                                <a href={'tel:' + clientPhone} className=" has-text-white has-text-weight-thin "> <FontAwesomeIcon className='' icon={faPhone} /></a>
                            </Button>
                            <Button className=' is-radiusless marginLeft10  is-black'   >
                                <a href={'mailto:' + clientEmail} className=" has-text-white has-text-weight-thin "> <FontAwesomeIcon className='' icon={faEnvelope} /></a>
                            </Button>
                            <br />

                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>
        )
    }

    // <div className=''
    // // onContextMenu={(e) => { e.preventDefault() }}
    // // onDragStart={(e) => { e.preventDefault() }} >

    render() {

        console.log('RERENDERING WEBSHOP')
        // let page = this.state.isStarted ? <MultiComp className='mainMultiComp' state={this.multipartState} /> : this.home

        return (
            <div className='' onContextMenu={(e) => { e.preventDefault() }} onDragStart={(e) => { e.preventDefault() }} >

                {this.state.showSuccessModal ? this.orderSuccessModal() : null}
                {this.state.showPaymentModal ? this.paymentModal() : null}
                {this.state.showContactModal ? this.contactModal() : null}
                {this.state.showSignInModal ? this.signInModal() : null}
                {this.state.showOrderInModal !== null ? this.orderModal() : null}
                {this.state.isWebAdmin ? this.adminNavBar() : (this.state.isOpenedInIFrame ? this.iFrameNavTabs() : this.navBar())}
                {this.getCurrentPage()}
            </div>
        )
    }



}

export default Webshop








