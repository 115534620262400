import React, { Component } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Heading, Button, Image
} from 'react-bulma-components/dist'

import SelectBox from './SelectBox'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { imageNamed } from '../utilities/utils'
import ColumnAux from './ColumnAux'


class Step extends Component {

    hasCustomInput = () => {
        let cI = null
        if (this.props.needsCustomInput) {
            if (this.props.opts[0]["customInput"] != null) {
                cI = this.props.opts[0]["customInput"]
            }
        }
        return cI
    }


    state = {
        selectedBoxKey: null,
        currentStep: 0,
        customInput: this.hasCustomInput() ?? "",
    }
    _mounted = false

    // customInput = ""

    customInputChanged= (e) => {
        // console.log('customINPUT CHSNGED')
        // console.log(e.target.value)
        // // console.log('e Target Value')
        // // console.log(e.target.value)

        // // this.customInput = e.target.value



        // let sk = {...this.state}
        // sk.customInput = e.target.value

        // this.setState(sk)

        this.custInputChange(e.target.value)

    }

    componentDidMount = () => {
        this._mounted = true

        // Handle Upload option
        // existingUploadImage

        if (this.props.completeObj){
            console.log('EXISTING COMPLETE OBJ -', )
            console.log(this.props.completeObj)
        }


        console.log('EXISTING STYLE KEY -', this.props.exitingSelectedKey)

        if (this.props.exitingSelectedKey) {
            if (this._mounted) {
                let ns = { ...this.state }
                ns.selectedBoxKey = this.props.exitingSelectedKey
                if (this.props.needsCustomInput) {
                    if (this.props.opts[0]["customInput"] != null) {
                        ns.customInput = this.props.opts[0]["customInput"]
                    }
                }
                this.setState(ns)
            }
        }
    }

    componentWillUnmount = () => {
        this._mounted = false
    }

    doneHandler = (isNext, isLast = false) => {
        if (isNext) {
            if (isLast) {
                this.props.onChange('done')
            } else {
                this.props.onChange('next')
                this.props.nextStep()
            }
        } else {
            this.props.previousStep()
            this.props.onChange('back')
        }

    }


    styleClicked = (style) => {
        console.log('Style is selected =', style)
        this.doneHandler(true)
    }


    custInputChange = (val) => {


        let thisValue = val

        let ns = { ...this.state }

        if (this.state.selectedBoxKey) {
            this.props.itemSelected(this.props.stepKey, this.state.selectedBoxKey, thisValue)
        }else{


            if (this._mounted) {
                let firstOptKey = null
                if (this.props.opts.length){
                    firstOptKey  = this.props.opts[0].key

                    ns.selectedBoxKey = firstOptKey
                    this.props.itemSelected(this.props.stepKey, firstOptKey, thisValue)

                }

            }


        }
        ns.customInput = thisValue
        this.setState(ns)
    }

    boxSelected = (key) => {
        // this.state.selectedBoxKey = key

        let custInput = this.state.customInput

        this.props.itemSelected(this.props.stepKey, key, custInput)


        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedBoxKey = `${key}`
            this.setState(ns)
        }


    }

    messageView = () => {
        console.log('BUILDING MESSAGE STEP')
        let imageVideoBox = null
        if (this.props.imageSrc || this.props.videoSrc) {
            imageVideoBox = (
                <div className='imageVideoContainer16by9 is-clipped is-centered has-text-centered' >
                    <Image className='is-clipped ' src={imageNamed(this.props.imageSrc)} size={this.props.photoRatio} />
                    {/* <video autoPlay={true} src={sampleVideo} playsInline={true} muted={true} controls={true}></video> */}
                </div>
            )
        }

        let points = null
        if (this.props.points) {
            points = (
                <ColumnAux noPaddingSides={true} isMiddle={true} size={10} >
                    <ul className='list has-background-transparent is-shadowless is-borderless'>
                        {this.props.points.map((pt, index) => {

                            return <li key={index} className='type60 list-item'> • {pt} </li>
                        })}
                    </ul>
                </ColumnAux>
            )
        }


        console.log('Got Measurement Unit =', this.props.currentUnit)

        return (

            <div className=' measurementsContainer'>
                <br />
                <Heading className='has-text-centered has-text-weight-normal padHorizontal10 stickTop' size={4}>
                    <p>{this.props.title}</p>
                </Heading>
                <p className='type60 has-text-centered padHorizontal10'>{this.props.subtitle}</p>



                {imageVideoBox}

                {this.props.showUnitChoice ? this.unitChoice() : null}
                {points}

                <div className=' marginTop30 stickyBottom has-text-centered'>

                    {this.props.isLast ?
                        <p className=''> <Button className=' is-radiusless nextButton   ' onClick={() => { this.doneHandler(true, true) }}> Done </Button>  </p>
                        :
                        <p className=''>
                            {/* <Button disabled={this.state.selectedValue === null && !this.props.skipable} className={this.props.isFirst ? 'is-radiusless nextButton is-black marginTop60' : 'is-radiusless nextButton marginTop40 is-black'} onClick={() => { this.doneHandler(true) }}> Next */}

                            <Button className={this.props.isFirst ? 'is-radiusless nextButton  ' : 'is-radiusless nextButton  '} onClick={() => { this.doneHandler(true) }}> {this.props.overrideNext ?? 'Next'}
                                <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='' icon={faArrowRight} /></i>
                            </Button>
                        </p>
                    }
                    {this.props.isFirst ? null :
                        <p className=''>
                            <Button className=' is-radiusless is-text has-text-grey-lighter ' onClick={() => { this.doneHandler(false) }}>
                                <i className=" has-texh-weight-thin "> <FontAwesomeIcon className='' icon={faArrowLeft} /></i>
                            </Button>
                        </p>
                    }
                </div>
            </div>

        )
    }


    photoBox = () => {

        const steps = this.props.opts.map((opt) => {
            return <SelectBox key={opt.key} boldText={this.props.boldText} name={opt.key} needsCustomInput={opt.needsCustomInput} inputChanged={this.customInputChanged} isFabric={this.props.isFabric} company={opt.company} price={opt.price} isSelected={this.state.selectedBoxKey === opt.key} isFirst={this.props.isFirst} isLast={this.props.isLast} paddingLess={this.props.paddingLess ?? false} isPhotoBox={this.props.isPhotoBox} photoRatio={this.props.photoRatio} imageSrc={opt.imageSrc} caption={opt.name} selectedPressed={this.boxSelected} />
        })

        return (
            <div className='myFlexContainer '>
                {steps}
            </div>
        )

        // return (
        //     <div className='myFlexContainer marginTop40'>
        //         <SelectBox key='1' name='1' isSelected={this.state.selectedBoxKey === '1'} imageSrc={singalButton} caption='One Button Casual One Button Casual One Button Casual' selectedPressed={this.boxSelected} />
        //         <SelectBox key='2' name='2' isSelected={this.state.selectedBoxKey === '2'} imageSrc={singalButton} caption='One Button Casual' selectedPressed={this.boxSelected} />
        //         <SelectBox key='3' name='3' isSelected={this.state.selectedBoxKey === '3'} imageSrc={singalButton} selectedPressed={this.boxSelected} />
        //         <SelectBox key='4' name='4' isSelected={this.state.selectedBoxKey === '4'} caption='One Button Casual' selectedPressed={this.boxSelected} />
        //         <SelectBox key='5' name='5' isSelected={this.state.selectedBoxKey === '5'} imageSrc={singalButton} caption='One Button Casual' selectedPressed={this.boxSelected} />


        //         {/* <Box selected className='is-radiusless  stylesBox myFlexItem' onClick={() => styleClicked('singalButton')} >
        //             <Image className='is-clipped ' src={singalButton} size={'1by1'} />
        //             <p className='marginTop10 is-size-7 has-text-centered' >One Button Casual</p>
        //         </Box>
        //         <Box className='is-radiusless  stylesBox myFlexItem' onClick={() => styleClicked('singalButton')} >
        //             <Image className='is-clipped' src={singalButton} size={'1by1'} />
        //             <p className='marginTop10 is-size-7 has-text-centered' >One Button Casual</p>
        //         </Box>
        //         <Box className='is-radiusless  stylesBox myFlexItem' onClick={() => styleClicked('singalButton')} >
        //             <Image className='is-clipped' src={singalButton} size={'1by1'} />
        //             <p className='marginTop10 is-size-7 has-text-centered' >One Button Casual</p>
        //         </Box> */}
        //     </div>
        // )
    }


    uploader = () => {
        return <Heading size={6}>Uploader</Heading>
    }


    styleView = () => {

        // let cI = ""

        // if (this.props.needsCustomInput) {
        //     if (this.props.opts[0]["customInput"] != null) {
        //         cI = this.props.opts[0]["customInput"]
        //     }
        // }
        // this.customInput = cI

        return (
        <div className=' measurementsContainer'>
            <Heading className='has-text-centered has-text-weight-normal padHorizontal10 stickTop' size={5}> {this.props.name} </Heading>
            {
                this.props.needsCustomInput ?
                <div> <input name='monogram' id='customInputText' placeholder='Enter text' maxlength={20} type='input' defaultValue={this.state.customInput} className='input has-text-weight-medium type55 pad10 marginBottom20' onChange={this.customInputChanged} /> </div>
                : null
            }
            <div className=''>
                {this.props.type === 'uploader' ? this.uploader : this.photoBox()}
            </div>
            
            <div className='marginTop30 stickyBottom has-text-centered'>

                {this.props.isLast ?
                    <p className=''> <Button hidden={this.state.selectedBoxKey === null && !this.props.skipable} className=' is-radiusless nextButton  ' onClick={() => { this.doneHandler(true, true) }}> Done </Button>  </p>
                    :
                    <p className=''>
                        <Button hidden={this.state.selectedBoxKey === null && !this.props.skipable} className={this.props.isFirst ? 'is-radiusless nextButton ' : 'is-radiusless nextButton  '} onClick={() => { this.doneHandler(true) }}> Next
                    <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='' icon={faArrowRight} /></i>
                        </Button>
                    </p>
                }

                {this.props.isFirst ? null :
                    <p className=''>
                        <Button className=' is-radiusless is-text has-text-grey-lighter ' onClick={() => { this.doneHandler(false) }}>
                            <i className=" has-texh-weight-thin "> <FontAwesomeIcon className='' icon={faArrowLeft} /></i>
                        </Button>
                    </p>
                }

            </div>
        </div>
    )}

    render() {

        let thisStep = <p></p>
        if (this.props.isMessageScreen === true) {
            thisStep = this.messageView()
        } else {
            thisStep = this.styleView()
        }

        return (
            thisStep
        )
    }
}

export default Step