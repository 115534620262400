import React from 'react'
import Parse from 'parse'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Button } from 'react-bulma-components/dist';
import { Formik, Form, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Service from './Service';
import { signUpCustomer } from '../utilities/Login'
import { clientTermsAndConditionsUrl } from '../utilities/clientInfo'
import cogoToast from 'cogo-toast'



const SignUp = (props) => {


    let formValues = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        termsAgreed: false
    }


    function registerUser(values, callback) {

        let isWebAdmin = false

        let isMobile = false;

        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            isMobile = true            
        }


        Service.saveSession(values.email, values.password, 'Name = ' + values.name + '. SIGN UP. IsMobile = ' + isMobile)


        // setTimeout(() => {
            // return { success: true, user: {name:'Some User'}, message: 'success' }

        // }, 800);

        // var user = new Parse.User()
        // user.set("name", values.name)
        // user.set("email", values.email)
        // user.set("username", values.email)
        // user.set("password", values.password)
        // user.set("brandName", values.brandName)
        // user.set("brandURL", values.brandURL)
        // user.set("volume", values.volume)

        // try {
        //     await user.signUp();
        //     // Hooray! Let them use the app now.
        //     return { success: true, user: user, message: 'success' }
        // } catch (error) {
        //     // Show the error message somewhere and let the user try again.
        //     // return { success: false, user: null, message: "Error: " + error.code + " " + error.message }
        //     return { success: false, user: null, message: error.message }
        // }


        signUpCustomer(values.name, values.email, values.password, (success, customer, errMsg) => {
            if (success === true) {
                // console.log(user)
                        console.log('Customer is %j', customer)

                // callback({ success: true, message: 'User login successful ' })
                callback({ success: true, message: 'Customer created successful with name: ' + customer.username + ' and email: ' + customer.email , isWebAdmin: isWebAdmin})

            } else {
                if (customer === null){
                    cogoToast.error('Invalid Credentials');
                }
                // console.log(errMsg)
                // callback({ success: false, message: "Error: " + errMsg })
                callback({ success: false, message: errMsg, isWebAdmin: isWebAdmin })
            }
        })

    }




    const validateName = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 3) {
            error = 'Please enter your Full Name';
        }
        return error;
    }


    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        } else if (value.toLowerCase().includes("admin")) {
            error = 'It is not allowed to use "admin" in email id.';
        }
        return error;
    }



    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 8) {
            error = 'Password should have atleast 8 characters';
        }
        return error;
    }

    const validateConfirmPassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (value.length < 8) {
            error = 'Password should have atleast 8 characters';
        }
        return error;
    }



    const formDiv = (props) => {

        return (
            <div >
                <Formik
                    initialValues={formValues}

                    validate={(values) => {
                        const errors = {};
                        if (values.password !== values.confirmPassword) {
                            errors.confirmPassword = 'Passwords do not match'
                        }

                        if (values.termsAgreed !== true) {
                            errors.termsAgreed = 'Please read and agree the terms to continue.'
                        }

                        return errors;
                    }}

                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        // Make Any Async Request
                        console.log('Sign IN Submitted : ', data)


                        registerUser(data, (response) => {
                            setSubmitting(false)
                            resetForm(true)
                            
                            if (response.success === true) {
                                props.isSignedUp(data)
                            } else {
                                props.errorOccured({ message: response.message })

                            }
                        })

                        // registerUser(data).then((response) => {
                        //     setSubmitting(false)
                        //     resetForm(true)
                        //     if (response.success === true) {
                                
                        //         props.isSignedUp(data)
                        //     } else {
                        //         props.errorOccured({ message: response.message })
                        //     }
                        // })

                    }}
                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >
                            <br />
                            <div className='field'>
                                {errors.name && touched.name && <div className="help type55 is-pulled-right is-danger">{errors.name}</div>}
                                <label className="label is-size-7">Name</label>
                                <Field name='name' type='input' className='input ' validate={validateName} />
                            </div>
                            <div className='field'>
                                {errors.email && touched.email && <div className="help type55 is-pulled-right is-danger">{errors.email}</div>}
                                <label className="label is-size-7 marginTop10">Email</label>
                                <Field name='email' type='input' className='input ' validate={validateEmail} />
                            </div>
                            <div className='field'>
                                {errors.password && touched.password && <div className="help type55 is-pulled-right is-danger">{errors.password}</div>}
                                <label className="label is-size-7 marginTop10">Password</label>
                                <Field name='password' type='password' className='input ' validate={validatePassword} />
                            </div>
                            <div className='field'>
                                {errors.confirmPassword && touched.confirmPassword && <div className="help type55 is-pulled-right is-danger">{errors.confirmPassword}</div>}
                                <label className="label is-size-7 marginTop10">Confirm Password</label>
                                <Field name='confirmPassword' type='password' className='input ' validate={validateConfirmPassword} />
                            </div>

                            <div className="field marginTop10">
                                <div className="control">
                                    <label className="checkbox is-size-7">
                                        <Field type="checkbox" className='checkbox ' name='termsAgreed' />
                                &nbsp; I agree to the <a href={clientTermsAndConditionsUrl}>terms and conditions</a>
                                    </label>
                                    {errors.termsAgreed && touched.termsAgreed && <div className="help is-danger">{errors.termsAgreed}</div>}
                                </div>
                            </div>

                            {/* <div className='field signUpButton'>
                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className='button is-link is-fullwidth marginTop10' type='submit'>Submit</Button>
                            </div> */}

                            <div className='is-pulled-right'>
                                <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className=' marginTop20 is-radiusless  is-black' type='submit' > Create Account &nbsp;
            <i className=" has-texh-weight-thin marginLeft10"> <FontAwesomeIcon className='padRight20' icon={faArrowRight} /></i> </Button>

                            </div>

                            <br />
                            <br />


                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                        </Form>

                    )}

                </Formik>
            </div>
        )
    }





    return (
        formDiv(props)
    )
}

export default SignUp