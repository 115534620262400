import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Box, Image } from 'react-bulma-components/dist'
import singalButton from '../assets/singalButton.png'
import { currencySymbol } from '../utilities/clientInfo'

const SelectBox = (props) => {


    let makeBlankASquare = false

    const inputChanged = (e) => {
        props.inputChanged(e.target.value)
    }

    const classes = () => {

        let classArray = 'is-radiusless stylesBox is-clipped'

        // if (hasSingalElement){
        //     classArray = classArray + ' chooseBox'
        // }else {
        // if (props.isSquare || (!props.imageSrc && !props.caption && makeBlankASquare)) {

        if (props.isSquare) {
            classArray = classArray + ' square160 chooseBox pad0'
        } else {
            classArray += ' myFlexItem '
            switch (props.photoRatio) {
                case '1by1':
                    if (props.isFabric){
                        classArray += ' pad0 longBox-150-220 ';
                    }else{
                        // classArray += ' pad0 longBox-150-200 ';
                        classArray += ' pad0 longBox-150-220 ';

                    }
                        ; break;
                case '3by4':
                    if (props.paddingLess === true) {
                        classArray += ' pad0 longBox-160-246'
                    } else {
                        classArray += ' pad0 longBox-160-220 ';
                    }
                    break;
                default: 
                if (props.isFabric){
                    classArray += ' pad0 longBox-150-220';

                }else{
                    // classArray += ' pad0 longBox-150-200 ';
                    classArray += ' pad0 longBox-150-220 ';

                }
            }
        }



        // }

        if (props.isSelected) {
            classArray += ' selected'
        }



        return classArray

    }



    const selectedPressed = (name) => {
        props.selectedPressed(name)
    }

    const imageBoxWithText = () => {

        let textClasses = ' marginTop10 type55 has-text-centered '

        if (props.boldText) {
            textClasses = ' pad10 has-items-vertical-center  has-text-centered has-text-weight-medium is-size-6'
        }
        if (props.imageSrc === null) {
            textClasses += ' verticalCenter pad10'
        }

        return <Box selected className={classes()} onClick={() => { selectedPressed(props.name) }} >
            {props.imageSrc ?
                <Image className='is-clipped ' src={props.imageSrc} size={props.photoRatio} />
                :
                null
            }

            <div className={textClasses} >{props.caption || props.name}</div>

            <div>



            {props.company ?
                <div className=' type45 has-text-centered  has-text-grey-light is-uppercase' >{props.company}</div>
            : null
            }

            {props.price ?
                <div className=' type45 has-text-centered  has-text-weight-semibold has-text-grey' >{currencySymbol}{props.price}</div>
            : null
            }

            {props.needsCustomInput ?
                // <div className=' field input has-text-centered  has-text-grey-light is-uppercase' >{props.company}</div>
                <input name='monogram' id='customInputText' max={"20"} type='input' onChange={ () => { inputChanged() }} inputProps = {{maxLength:"20"}} maxLength={"20"} className='input has-text-weight-medium type55 pad10'/>
            : null
            }

        </div>





        </Box>
    }

    const imageBox = () => {
        return <Box selected className={classes() + ' myFlexContiner'} onClick={() => { selectedPressed(props.name) }} >

            {props.isSquare ?
                <div className='has-items-vertical-center square140'> <Image className='is-clipped square140' src={props.imageSrc} size={'1by1'} />
                </div>
                :
                <Image className='is-clipped ' src={props.imageSrc} size={'1by1'} />
            }

        </Box>
    }

    const textBox = () => {
        return <Box selected className={classes()} onClick={() => { selectedPressed(props.name) }} >
            {props.isSquare ?
                <div className='has-items-vertical-center'> <p className='has-text-weight-medium  is-size-7 has-text-centered' >{props.caption ?? '...'}</p> </div>
                :
                <p className='has-text-weight-medium  type55 has-text-centered' >{props.caption ?? '...'}</p>
            }

        </Box>
    }

    const getBox = () => {
        // if (props.imageSrc && props.caption) {
        return imageBoxWithText()
        // }
        // if (props.imageSrc) {
        //     return imageBox()
        // }
        // return textBox()


    }

    // key, isSelected, imageSrc, caption

    return (

        getBox()
    )

}

export default SelectBox