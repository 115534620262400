/* eslint-disable */

import React, { Component } from 'react'
import StepWizard from 'react-step-wizard'
import Step from './Step'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import {
    Columns, Heading, Container, Section,
    Button, Navbar, Hero, Tabs,
    Box, Image, Table, Modal
} from 'react-bulma-components/dist'

import ColumnAux from './ColumnAux'
import LoadingAux from './LoadingAux'
import ChooseBox from './ChooseBox'

import { imageNamed, webImageNamed, PRODUCT_TYPE, makeid } from '../utilities/utils'
import { C_Product, Product } from '../utilities/classes'
import { currencySymbol } from '../utilities/clientInfo'





// console.log('Images Folder =', allImages)
// console.log('Images with ````` =', imageNamed('`````'))



class WizardForShirt extends Component {
   
    basePrice = 400

    STEP_KEYS = {
        productType: '',
        collar: 'COLLAR',
        cuff: 'CUFF',
        // length: 'Length',
        // lowerPockets: 'Lower Pockets',
        // vent: 'Vent',
        // sleeveButtons: 'Sleeve Buttons',
        // lining: 'Lining',
        monogram: 'MONOGRAM',
        
        tone: 'TONE',
        fabric: 'FABRIC'
    }

    TONE_KEYS = {
        greys: 'greys',
        blues: 'blues',
        blacks: 'blacks',
        playful: 'playfuls'
    }

    STYLE_START = {
        create: 'Create Style',
        upload: 'Upload Style',
        select: 'Select Style'
    }


    // selected_Collar : collar
    // selected_Cuff : cuff
    // selected_Length : length
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric

    state = {
        uploadImage: null,
        
        selected_Collar: this.props.editingProduct ? this.props.editingProduct.selectedSelections.collar : null,
        selected_Cuff: this.props.editingProduct ? this.props.editingProduct.selectedSelections.cuff : null,
        selected_Length: this.props.editingProduct ? this.props.editingProduct.selectedSelections.length : null,
        // selected_LowerPockets: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lowerPockets : null,
        // selected_Vent: this.props.editingProduct ? this.props.editingProduct.selectedSelections.vent : null,
        // selected_SleeveButtons: this.props.editingProduct ? this.props.editingProduct.selectedSelections.sleeveButtons : null,
        // selected_Lining: this.props.editingProduct ? this.props.editingProduct.selectedSelections.lining : null,
        selected_Monogram: this.props.editingProduct ? this.props.editingProduct.selectedSelections.monogram : null,

        selected_Tone: this.props.editingProduct ? this.props.editingProduct.selectedSelections.tone : null,
        selected_Fabric: this.props.editingProduct ? this.props.editingProduct.selectedSelections.fabric : null,

        isComplete: false,
        isCancelled: false,
        selectedCreateOrUpload: this.STYLE_START.create,
        editingProduct: this.props.editingProduct ?? null,
        productType: PRODUCT_TYPE.jacket
    }



    _mounted = false


    componentDidMount = () => {
        this._mounted = true

        let ns = { ...this.state }
        if (this.props.editingProduct) {
            ns.editingProduct = this.props.editingProduct
        }
        if (this.props.productType) {
            ns.productType = this.props.productType
        }

        if (this.props.editingProduct) {
            let existingStyle = { ...this.props.editingProduct }
            ns.editingProduct = existingStyle
            // console.log("EDITING WIZRAD OF JACKET")
            // console.log(existingStyle)

            ns.selected_Collar = existingStyle.selectedSelections.collar
            ns.selected_Cuff = existingStyle.selectedSelections.cuff
            ns.selected_Length = existingStyle.selectedSelections.length
            // ns.selected_LowerPockets = existingStyle.selectedSelections.lowerPockets
            // ns.selected_Vent = existingStyle.selectedSelections.vent
            // ns.selected_SleeveButtons = existingStyle.selectedSelections.sleeveButtons
            // ns.selected_Lining = existingStyle.selectedSelections.lining
            ns.selected_Monogram = existingStyle.selectedSelections.monogram
            ns.selected_Tone = existingStyle.selectedSelections.tone
            ns.selected_Fabric = existingStyle.selectedSelections.fabric

        }


        this.setState(ns)

    }

    componentWillUnmount = () => {
        this._mounted = false
    }




        // selected_Collar : collar
    // selected_Cuff : cuff
    // selected_Length : length
    // selected_LowerPockets : lowerPockets
    // selected_Vent : vent
    // selected_SleeveButtons : sleeveButtons
    // selected_Lining : lining
    // selected_Monogram : monogram
    // selected_Tone : tone
    // selected_Fabric : fabric


    // COLLAR, ["SHIRT"], ["CUTAWAY", "STANDARD POINTED", "WIDE CUTAWAY", "WITH ARCH", "WITH EYELET", "WITH COLLAR TAB", "WITH BUTTON DOWN", "WITH HIDDEN BUTTON DOWN"]
     // FRONT
     collar_Opts = [
        { optType:"COLLAR", styleSelectionId: "", key: 'CUTAWAY', name: 'CUTAWAY', imageSrc: webImageNamed('shirt/collar/CUTAWAY.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'STANDARD POINTED', name: 'STANDARD POINTED', imageSrc: webImageNamed('shirt/collar/POINTEDstandard.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WIDE CUTAWAY', name: 'WIDE CUTAWAY', imageSrc: webImageNamed('shirt/collar/WIDEcutaway.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WITH ARCH', name: 'WITH ARCH', imageSrc: webImageNamed('shirt/collar/POINTEDwithARCH.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WITH EYELET', name: 'WITH EYELET', imageSrc: webImageNamed('shirt/collar/POINTEDwithEYELET.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WITH COLLAR TAB', name: 'WITH COLLAR TAB', imageSrc: webImageNamed('shirt/collar/POINTEDwithCOLLARTAB.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WITH BUTTON DOWN', name: 'WITH BUTTON DOWN', imageSrc: webImageNamed('shirt/collar/STANDARDwithBUTTONdown.jpg') },
        { optType:"COLLAR", styleSelectionId: "", key: 'WITH HIDDEN BUTTON DOWN', name: 'WITH HIDDEN BUTTON DOWN', imageSrc: webImageNamed('shirt/collar/POINTEDwithHIDDENbuttonDOWN.jpg') },
    ]
    collar_Step = () => {
        return {
            name: 'How would you like the Collar?',
            key: this.STEP_KEYS.collar,
            opts: this.collar_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }

    
    // CUFF, ["SHIRT"], ["STANDARD", "ROUNDED", "HEX", "SINGLE CUFF 1 BUTTON", "SINGLE CUFF 2 BUTTONS"]
    // LAPEL
    cuff_Opts = [
        {optType:"CUFF", styleSelectionId: "", key: 'FRENCH CUFF STANDARD', name: 'STANDARD', imageSrc: webImageNamed('shirt/cuff/FRENCHcuffSTANDARD.jpg') },
        {optType:"CUFF", styleSelectionId: "", key: 'FRENCH CUFF ROUNDED', name: 'ROUNDED', imageSrc: webImageNamed('shirt/cuff/FRENCHcuffROUNDED.jpg') },
        {optType:"CUFF", styleSelectionId: "", key: 'FRENCH CUFF HEX', name: 'HEX', imageSrc: webImageNamed('shirt/cuff/FRENCHcuffHEX.jpg') },
        {optType:"CUFF", styleSelectionId: "", key: 'SINGLE CUFF 1 BUTTON', name: 'SINGLE CUFF 1 BUTTON', imageSrc: webImageNamed('shirt/cuff/SINGLEcuffOneBUTTON.jpg') },
        {optType:"CUFF", styleSelectionId: "", key: 'SINGLE CUFF 2 BUTTONS', name: 'SINGLE CUFF 2 BUTTONS', imageSrc: webImageNamed('shirt/cuff/SINGLEcuffTwoBUTTON.jpg') },
    ]
    cuff_Step = () => {
        return {
            name: 'What Cuffs would suit you the best?',
            key: this.STEP_KEYS.cuff,
            opts: this.cuff_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    
    
    // LENGTH, ["SHIRT"], ["TUCKED: LONG", "UNTUCKED: STANDARD"]
    // LAPEL WIDTH
    length_Opts = [
        {optType:"LENGTH", styleSelectionId: "", key: 'TUCKED: LONG', name: 'TUCKED: LONG', imageSrc: webImageNamed('shirt/length/TUCKEDlong.jpg') },
        {optType:"LENGTH", styleSelectionId: "", key: 'UNTUCKED: STANDARD', name: 'UNTUCKED: STANDARD', imageSrc: webImageNamed('shirt/length/UNTUCKEDstandard.jpg') },
    ]
    length_Step = () => {
        return {
            name: 'Shirt Length Preference',
            key: this.STEP_KEYS.length,
            opts: this.length_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    

    // MONOGRAM, ["SHIRT"], ["LEFT CUFF", "RIGHT CUFF"]
    // MONOGRAM
    monogram_Opts = [
        {optType:"MONOGRAM", styleSelectionId: "", key: 'LEFT CUFF', name: 'LEFT CUFF', imageSrc: webImageNamed('shirt/monogram/LEFTcuff.jpg'), customInput:"" },
        {optType:"MONOGRAM", styleSelectionId: "", key: 'RIGHT CUFF', name: 'RIGHT CUFF', imageSrc: webImageNamed('shirt/monogram/RIGHTcuff.jpg'), customInput:"" },
    ]
    monogram_Step = () => {
        return {
            name: 'If and where you would like to have monogram',
            key: this.STEP_KEYS.monogram,
            opts: this.monogram_Opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1',
            skipable: true,
            paddingLess: false,
            boldText: false,
            needsCustomInput: true
        }
    }
    

    // TONE, ["SHIRT"], []
    // TONE
    toneOpts = [
        {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.blues, name: 'Blues', imageSrc: webImageNamed('blues') },
        {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.blacks, name: 'Blacks', imageSrc: webImageNamed('blacks') },
        {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.greys, name: 'Greys', imageSrc: webImageNamed('greys') },
        {optType:"TONE", styleSelectionId: "", key: this.TONE_KEYS.playful, name: 'Playful', imageSrc: webImageNamed('playful') },
        // { key: 'other', name: 'Other', imageSrc: null },
    ]
    toneStep = () => {
        // Change images here with respect to button choices
        return {
            name: 'What Color Tone are we looking for?',
            key: this.STEP_KEYS.tone,
            opts: this.toneOpts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '3by4',
            skipable: false,
            paddingLess: false,
            boldText: false
        }
    }
    
    // FABRIC, ["SHIRT"], []
    fabric_greyOpts = [
        {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'Grey 1', imageSrc: webImageNamed('fab_grey1') , price:210 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'Grey 2', imageSrc: webImageNamed('fab_grey2') , price:130 , company:'Zegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'Grey 3', imageSrc: webImageNamed('fab_grey3') , price:320 , company:'Freggi'},
        {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'Grey 4', imageSrc: webImageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'Grey 5', imageSrc: webImageNamed('fab_grey1') , price:210 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '6', name: 'Grey 6', imageSrc: webImageNamed('fab_grey2') , price:130 , company:'Zegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '7', name: 'Grey 7', imageSrc: webImageNamed('fab_grey3') , price:320 , company:'Freggi'},
        {optType:"FABRIC", styleSelectionId: "", key: '8', name: 'Grey 8', imageSrc: webImageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        {optType:"FABRIC", styleSelectionId: "", key: '9', name: 'Grey 9', imageSrc: webImageNamed('fab_grey1') , price:210 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '10', name: 'Grey 10', imageSrc: webImageNamed('fab_grey2') , price:130 , company:'Zegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '11', name: 'Grey 11', imageSrc: webImageNamed('fab_grey3') , price:320 , company:'Freggi'},
        {optType:"FABRIC", styleSelectionId: "", key: '12', name: 'Grey 12', imageSrc: webImageNamed('fab_grey4') , price:100 , company:'Loro Piano'},
        {optType:"FABRIC", styleSelectionId: "", key: '13', name: 'Grey 13', imageSrc: webImageNamed('fab_grey1') , price:210 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '14', name: 'Grey 14', imageSrc: webImageNamed('fab_grey2') , price:130 , company:'Zegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '15', name: 'Grey 15', imageSrc: webImageNamed('fab_grey3') , price:320 , company:'Freggi'}
    ]
    fabric_blueOpts = [
        {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'Blue 1', imageSrc: webImageNamed('fab_blue1')  , price:320 , company:'Armegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'Blue 2', imageSrc: webImageNamed('fab_blue2')  , price:90  , company:'Ferrocia'},
        {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'Blue 3', imageSrc: webImageNamed('fab_blue3')  , price:420 , company:'Muchos'},
        {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'Blue 4', imageSrc: webImageNamed('fab_blue4')  , price:120 , company:'Scabal'},
    ]
    fabric_blackOpts = [
        {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'Black 1', imageSrc: webImageNamed('fab_black1')  , price:145 , company:'Curruti'},
        {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'Black 2', imageSrc: webImageNamed('fab_black2')  , price:622 , company:'Formid'},
        {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'Black 3', imageSrc: webImageNamed('fab_black3')  , price:366 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'Black 4', imageSrc: webImageNamed('fab_black4')  , price:445 , company:'Scabal'},
    ]
    fabric_playOpts = [
        {optType:"FABRIC", styleSelectionId: "", key: '1', name: 'Play 1', imageSrc: webImageNamed('fab_play1')  , price:233 , company:'Zegna'},
        {optType:"FABRIC", styleSelectionId: "", key: '2', name: 'Play 2', imageSrc: webImageNamed('fab_play2')  , price:365 , company:'Loro Piano'},
        {optType:"FABRIC", styleSelectionId: "", key: '3', name: 'Play 3', imageSrc: webImageNamed('fab_play3')  , price:647 , company:'Scabal'},
        {optType:"FABRIC", styleSelectionId: "", key: '4', name: 'Play 4', imageSrc: webImageNamed('fab_play4')  , price:442 , company:'Cerruti'},
        {optType:"FABRIC", styleSelectionId: "", key: '5', name: 'Not Sure! You can choose for me.', imageSrc: null , company:'Choice Fabric',  price:250}
    ]
    


    fabricImageForSelectedKey = (tone, key) => {
        let opts = null
        switch (tone) {
            case this.TONE_KEYS.blacks: opts = this.fabric_blackOpts; break;
            case this.TONE_KEYS.blues: opts = this.fabric_blueOpts; break;
            case this.TONE_KEYS.playful: opts = this.fabric_playOpts; break;
            default: opts = this.fabric_greyOpts; break;
        }
        let thisObj = opts.filter((obj) => {
            return obj.key === key
        })
        return webImageNamed(thisObj[0].imageSrc)
    }


    optsForTone = (tone) => {
        switch (tone) {
            case this.TONE_KEYS.blacks: return this.fabric_blackOpts;
            case this.TONE_KEYS.blues: return this.fabric_blueOpts;
            case this.TONE_KEYS.playful: return this.fabric_playOpts;
            default: return this.fabric_greyOpts;
        }
    }

    fabricStep = () => {
        // Change images here with respect to button choices

        // console.log('Calculating Fabric again. SelectedTone =', this.state.selected_Tone.key)

        let thisTone = this.state.selected_Tone ? this.state.selected_Tone.key : this.TONE_KEYS.blues

        let opts = this.optsForTone(thisTone)


        console.log('OPTS NOW =', opts.k)
        console.log( opts)


        return {
            name: 'Pick a Fabric',
            key: this.STEP_KEYS.fabric,
            opts: opts,
            selectedOptKey: null,
            isPhotoBox: true,
            photoRatio: '1by1'
        }
    }








    scrollToTop = () => {
        const c = document.documentElement.scrollTop || document.body.scrollTop;
        if (c > 0) {
            window.requestAnimationFrame(this.scrollToTop);
            window.scrollTo(0, c - c / 8);
        }
    }


    onChangeHandler = (direction) => {
        console.log('CHANGE HAPPEND -', direction)
        this.scrollToTop()
        if (direction === 'done') {
            this.donePressed()
        }
        if (direction === 'cancelled') {
            // Cancel Pressed
        }
    }



     

    donePressed = () => {

        // Create And Save Style Here first


        if (this._mounted) {
            let ns = { ...this.state }
            ns.isComplete = true
            this.setState(ns)


            // CHECK 3
            // const frontImageObj = this.fro.filter((obj) => {
            //     return obj.key === this.state.selected_Collar
            // })
            let mainImage = imageNamed('singalButton')

            if (this.state.selected_Collar.imageSrc){
                mainImage = this.state.selected_Collar.imageSrc
            }

            let fabricImage = null

            if (this.state.selected_Fabric.imageSrc){
                fabricImage = this.state.selected_Fabric.imageSrc
            }
            let styleName = this.state.productType + "-" + makeid(3)
            
            if (this.state.editingProduct) {
                uniqId = this.state.editingProduct.key
                styleName = this.state.editingProduct.styleName
            }


            let allSelelected = [this.state.selected_Collar, this.state.selected_Cuff, this.state.selected_Length, this.state.selected_Monogram, this.state.selected_Tone, this.state.selected_Fabric]


            let finalPrice = this.basePrice
            let addedPriceObjs = [] 
            let addOnPrice = 0

            allSelelected.forEach( asel => {
                if (asel != null) {
                    if (asel.price) {
                        finalPrice += asel.price
                        addOnPrice += asel.price
                        addedPriceObjs.push( { type: asel.optType, name: asel.name, price: asel.price })
                    }
                }

            })



            // allSelelected.forEach( selectedObj  => {
            //     if (selelectedObj.price) {
            //         finalPrice += selectedObj.price
            //         addedPriceObjs.push( { type: selectedObj.optType, name: selectedObj.name, price: selectedObj.price })
            //     }
            // })

            console.log("this.state.selected_Collar = ")
            console.log(this.state.selected_Collar)

            let style = {
                collar: this.state.selected_Collar,
                cuff: this.state.selected_Cuff,
                length: this.state.selected_Length,
                // lowerPockets: this.state.selected_LowerPockets,
                // vent: this.state.selected_Vent,
                // sleeveButtons: this.state.selected_SleeveButtons,
                // lining: this.state.selected_Lining,
                monogram: this.state.selected_Monogram,
                tone: this.state.selected_Tone,
                fabric: this.state.selected_Fabric

            }


            let styleArr = Object.keys(style).map(function (key) {

                return [key, style[key]];
            });

            let withoutNull = styleArr.filter(ar => {
                return !ar.includes(null)
            })


            let description = ' '
            withoutNull.forEach((ms, index) => {
                let type = ms[0]
                let val = ms[1].name


                description += ' ' + type + ':' + val 
                if (ms[1].customInput){
                    description += `<${ms[1].customInput}> `
                }
                if (index == (withoutNull.length - 1)){
                    description += ""
                }else{
                    description += ","
                }


            })


            // let prodObj = new C_Product(null,true,this.state.productType, uniqId,"",null, null,[], [], style, "", description, false, null, true, false, null, true, [], currencySymbol ,this.basePrice,addOnPrice, 0,0, finalPrice, addedPriceObjs, styleName, fabricImage, mainImage)

            // prodObj.key = 'id' + (new Date()).getTime();


            // // let prodObj = {
            // //     key: uniqId,
            // //     styleName:  styleName,
            // //     frontImage: mainImage,
            // //     fabricImage: fabricImage,
            // //     description: description,
            // //     type: this.state.productType,
            // //     basePrice: this.basePrice,
            // //     price: finalPrice,
            // //     addedPriceObjs: addedPriceObjs,
            // //     selectedSelections: style
            // // }

            let prod = Product.init()
            prod.type = this.state.productType
            prod.conciergeSelections = [style]

            let fabricAndToneMaterials = []

            let concToTwSelections = Object.values(style).map( (obj) => {
                if (obj === null) {
                    return null
                }

                if (obj.optType.toLowerCase() === 'fabric'){
                    fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                    return null
                }

                if (obj.optType.toLowerCase() === 'tone'){
                    return null
                    // fabricAndToneMaterials.push("Fabric - " + obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "")  )
                }

                return { 
                    "key" : obj.optType, 
                    "value" : obj.name  + (obj.customInput ?  `<${obj.customInput}>` : "") , 
                    "styleSelectionId": obj.styleSelectionId ?? "",
                    "imageSrc": obj.imageSrc ?? null, 
                    "isConciergeSelection" : true
                 }
            })

            concToTwSelections = concToTwSelections.filter( (obj) => {
                return (obj !== null)
            })

            prod.materialToSource = fabricAndToneMaterials


            prod.styleSelections = concToTwSelections

            prod.basePrice = this.basePrice
            prod.addOnPrice = addOnPrice
            prod.name = styleName
            prod.taxOnPrice = 0
            prod.shippingPrice = 0
            prod.taxDescription = ""
            prod.shippingDescription = ""
            prod.price = finalPrice
            prod.addedPriceObjs = addedPriceObjs
            prod.styleName = styleName
            prod.imageUrl = mainImage
            prod.fabricImage = fabricImage
            prod.imagesUrls = [mainImage, fabricImage]
            prod.description = description
            prod.isBespoke = true
            prod.isViaConcierge = true

            console.log('Sending Style Object -', prod)

            this.props.isDone('created', prod)

        }
    }

    stepItemSelected = (stepKey, itemKey, customInput) => {
        console.log('Step selection happend in StepKey =' + stepKey + ', ItemKey= ', itemKey)

        if (this._mounted) {
            console.log('IS MOUNTED')
            let ns = { ...this.state }
            console.log("GOT Custom INPUT " + customInput)

                            // selected_Collar : collar
                // selected_Cuff : cuff
                // selected_Length : length
                // selected_LowerPockets : lowerPockets
                // selected_Vent : vent
                // selected_SleeveButtons : sleeveButtons
                // selected_Lining : lining
                // selected_Monogram : monogram
                // selected_Tone : tone
                // selected_Fabric : fabric

                // ns[stepKey] = itemKey

                // console.log("ITEM KEY =" + itemKey)
                // let firstOPT = this.collar_Opts.filter( opt => { return (opt.key === itemKey)})


                // console.log("FIRST OPT")
                // console.log(firstOPT)


            switch (stepKey) {
                case this.STEP_KEYS.collar: ns.selected_Collar =  this.collar_Opts.filter( opt => { return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.cuff: ns.selected_Cuff = this.cuff_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.length: ns.selected_Length = this.length_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.lowerPockets: ns.selected_LowerPockets = this.lowerPockets_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.vent: ns.selected_Vent = this.vent_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.sleeveButtons: ns.selected_SleeveButtons = this.sleeveButtons_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                // case this.STEP_KEYS.lining: ns.selected_Lining = this.lining_Opts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.monogram: ns.selected_Monogram = this.monogram_Opts.filter( opt => {return opt.key === itemKey})[0] ; if (customInput && customInput !== ""){ ns.selected_Monogram["customInput"] = customInput } ; break;
                case this.STEP_KEYS.tone: ns.selected_Tone = this.toneOpts.filter( opt => {return opt.key === itemKey})[0]; break;
                case this.STEP_KEYS.fabric: ns.selected_Fabric = this.fabricStep().opts.filter( opt => {return opt.key === itemKey})[0]; break;
                default: break;
            }
            this.setState(ns)
        }

    }

    createUploadChoiceMade = (name, code) => {
        if (this._mounted) {
            let ns = { ...this.state }
            ns.selectedCreateOrUpload = code
            this.setState(ns)
        }
    }

    createUploadChooser = () => {
        // return <div> <Box className='is-radiusless is-shadowless layerGreyBG  minHeight300 myFlexContainer '>
        return <div> <Box className='is-radiusless is-shadowless  minHeight300 myFlexContainer '>
            <ChooseBox choices={[{ name: 'Create Style', code: this.STYLE_START.create }, { name: 'Upload Style', code: this.STYLE_START.upload }]} choiceMade={this.createUploadChoiceMade} />
            {/* <ChooseBox choices={[{ name: 'Choose Style' }, { name: 'Upload Style' }]} choiceMade={this.choiceMadeInChooseBox} /> */}
        </Box>
            <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button>
        </div>
    }




    uploadImageStarted = () => {
        console.log('UPLOAD IMAGE STARTED IN WIZARD')
    }






    jacketWizard = () => {


        // selected_Collar : collar
        // selected_Cuff : cuff
        // selected_Length : length
        // selected_LowerPockets : lowerPockets
        // selected_Vent : vent
        // selected_SleeveButtons : sleeveButtons
        // selected_Lining : lining
        // selected_Monogram : monogram
        // selected_Tone : tone
        // selected_Fabric : fabric

    
    // let productStep = this.productStep()
    let collar_Step = this.collar_Step()
    let cuff_Step = this.cuff_Step()
    let length_Step = this.length_Step()
    // let lowerPockets_Step = this.lowerPockets_Step()
    // let vent_Step = this.vent_Step()
    // let sleeveButtons_Step = this.sleeveButtons_Step()
    // let lining_Step = this.lining_Step()
    let monogram_Step = this.monogram_Step()
    let toneStep = this.toneStep()
    let fabricStep = this.fabricStep()


    let title = "Let's Create a Style"
    let subtitle = 'In just few steps we will create a new style by just hand-picking what you like.'
    let points = [
        'To make it easy we pick the cuts and tones before finding the fabric.',
        'We will measure you right after we have created all the needed styles.'
    ]

    return (<div >
        {/* <div ref={this.mainStepperDiv}></div> */}
        {(this.state.isComplete || this.state.isCancelled) ?
            <Heading size={6}> Your Style Has Been Saved <Button className='is-text is-small has-text-weight-medium is-pulled-right'> Edit </Button> </Heading>
            :
            <StepWizard className=' fadeIn'>
                {/* {showUploader ? <Step type='uploader' skipable={true} isFirst={true} existingUploadImage={this.state.uploadImage} uploadImagePressed={this.uploadImageStarted} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  ></Step> : null} */}
               {this.state.editingProduct ? null : <Step name="Welcome" imageSrc={imageNamed('IconBg-Style')} overrideNext='Start' isMessageScreen={true} title={title} subtitle={subtitle} points={points} isFirst={true} skipable={false} stepKey={"12wjkbwj3"} exitingSelectedKey={null} opts={null} isPhotoBox={false} photoRatio={'16by9'} onChange={this.onChangeHandler} itemSelected={null}  ></Step> }  

               <Step name={collar_Step.name}  skipable={collar_Step.skipable ?? false} stepKey={collar_Step.key }     exitingSelectedKey={this.state.selected_Collar ? this.state.selected_Collar.key  : null }     opts={collar_Step.opts} isFirst={true} isPhotoBox={collar_Step.isPhotoBox} photoRatio={collar_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={collar_Step.boldText ?? false}  boldText={collar_Step.boldText ?? false} ></Step>
               <Step name={cuff_Step.name}         skipable={cuff_Step.skipable ?? false} stepKey={cuff_Step.key }                   exitingSelectedKey={this.state.selected_Cuff ? this.state.selected_Cuff.key         : null }            opts={cuff_Step.opts} isFirst={false} isPhotoBox={cuff_Step.isPhotoBox} photoRatio={cuff_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={cuff_Step.boldText ?? false}  boldText={cuff_Step.boldText ?? false} ></Step>
               <Step name={length_Step.name}    skipable={length_Step.skipable ?? false} stepKey={length_Step.key }         exitingSelectedKey={this.state.selected_Length ? this.state.selected_Length.key    : null }       opts={length_Step.opts} isFirst={false} isPhotoBox={length_Step.isPhotoBox} photoRatio={length_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={length_Step.boldText ?? false}  boldText={length_Step.boldText ?? false} ></Step>               
               {/* <Step name={lowerPockets_Step.name}  skipable={lowerPockets_Step.skipable ?? false} stepKey={lowerPockets_Step.key }     exitingSelectedKey={this.state.selected_LowerPockets ? this.state.selected_LowerPockets.key  : null }     opts={lowerPockets_Step.opts} isFirst={false} isPhotoBox={lowerPockets_Step.isPhotoBox} photoRatio={lowerPockets_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lowerPockets_Step.boldText ?? false}  boldText={lowerPockets_Step.boldText ?? false} ></Step>
               <Step name={vent_Step.name}          skipable={vent_Step.skipable ?? false} stepKey={vent_Step.key }                     exitingSelectedKey={this.state.selected_Vent ? this.state.selected_Vent.key          : null }             opts={vent_Step.opts} isFirst={false} isPhotoBox={vent_Step.isPhotoBox} photoRatio={vent_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={vent_Step.boldText ?? false}  boldText={vent_Step.boldText ?? false} ></Step>
               <Step name={sleeveButtons_Step.name} skipable={sleeveButtons_Step.skipable ?? false} stepKey={sleeveButtons_Step.key }   exitingSelectedKey={this.state.selected_SleeveButtons ? this.state.selected_SleeveButtons.key : null }    opts={sleeveButtons_Step.opts} isFirst={false} isPhotoBox={sleeveButtons_Step.isPhotoBox} photoRatio={sleeveButtons_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={sleeveButtons_Step.boldText ?? false}  boldText={sleeveButtons_Step.boldText ?? false} ></Step>
               <Step name={lining_Step.name}        skipable={lining_Step.skipable ?? false} stepKey={lining_Step.key }                 exitingSelectedKey={this.state.selected_Lining ? this.state.selected_Lining.key        : null }           opts={lining_Step.opts} isFirst={false} isPhotoBox={lining_Step.isPhotoBox} photoRatio={lining_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={lining_Step.boldText ?? false}  boldText={lining_Step.boldText ?? false} ></Step> */}
               <Step name={monogram_Step.name}   needsCustomInput={true}   skipable={monogram_Step.skipable ?? false} stepKey={monogram_Step.key }             exitingSelectedKey={this.state.selected_Monogram ? this.state.selected_Monogram.key      : null }         opts={monogram_Step.opts} isFirst={false} isPhotoBox={monogram_Step.isPhotoBox} photoRatio={monogram_Step.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={monogram_Step.boldText ?? false}  boldText={monogram_Step.boldText ?? false} ></Step>
               <Step name={toneStep.name}           skipable={toneStep.skipable ?? false} stepKey={toneStep.key }                       exitingSelectedKey={this.state.selected_Tone ? this.state.selected_Tone.key          : null }             opts={toneStep.opts} isFirst={false} isPhotoBox={toneStep.isPhotoBox} photoRatio={toneStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={false} paddingLess={toneStep.boldText ?? false}  boldText={toneStep.boldText ?? false} ></Step>
               <Step name={fabricStep.name}         skipable={fabricStep.skipable ?? false} stepKey={fabricStep.key }                   exitingSelectedKey={this.state.selected_Fabric ? this.state.selected_Fabric.key        : null }           opts={fabricStep.opts} isFirst={false} isPhotoBox={fabricStep.isPhotoBox} photoRatio={fabricStep.photoRatio} onChange={this.onChangeHandler} itemSelected={this.stepItemSelected}  isLast={true} paddingLess={fabricStep.boldText ?? false}  boldText={fabricStep.boldText ?? false} ></Step>

            </StepWizard>
        }
        {/* <Button onClick={this.props.cancelPressed} className='is-text type50 no-decoration is-small has-text-grey-light'>Cancel</Button> */}
    </div>
    )
}






    

    render() {
        return (
            this.jacketWizard()
        )
    }

}


export default WizardForShirt
